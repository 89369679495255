import { useFeature } from '@farmersdog/ab-testing';
import {
  AnonymousFeatures,
  UserFeatures,
  FeatureName,
  OnOffTreatments,
} from './features';

export function useVetPortalLunchAndLearn() {
  const { treatment } = useFeature<
    AnonymousFeatures,
    UserFeatures,
    FeatureName
  >(FeatureName.HEALTH_VET_PORTAL_LUNCH_AND_LEARN_REQUEST, {});

  const showVetPortalLunchAndLearn = treatment === OnOffTreatments.on;

  return {
    showVetPortalLunchAndLearn,
  };
}
