import cookie from 'js-cookie';
import { v1 as uuidv1 } from 'uuid';

import { local } from '@farmersdog/utils';

export const SEGMENT_ANONYMOUS_ID_COOKIE_KEY = 'ajs_anonymous_id';
export const SEGMENT_ANONYMOUS_ID_LOCAL_STORAGE_KEY = 'ajs_anonymous_id';

export const ANONYMOUS_ID_MISSING_VALUE = 'no-anonymous-id-set';

export const cookieOptions: cookie.CookieAttributes = {
  expires: 365,
  path: '/',
  sameSite: 'Lax',
  secure: false,
};

/**
 * Retrieve the current anonymous ID
 *
 * @returns - An anonymous ID string or null if non-existent
 */
function get(): string {
  return (
    cookie.get(SEGMENT_ANONYMOUS_ID_COOKIE_KEY) ?? ANONYMOUS_ID_MISSING_VALUE
  );
}

/**
 * Set the anonymous ID
 */
function set(anonymousId: string): void {
  // Segment stores these in both cookie and local storage, so writing to both seems prudent
  // https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/cookie-validity-update/#storage-priority
  local.setItem(SEGMENT_ANONYMOUS_ID_LOCAL_STORAGE_KEY, anonymousId);
  // mimicking Segment cookie settings
  // https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/cookie-validity-update/#cookie-settings
  cookie.set(SEGMENT_ANONYMOUS_ID_COOKIE_KEY, anonymousId, cookieOptions);
}

/**
 * Set the anonymous ID if it doesn't already exist
 */

function createIfNotExists(): void {
  const currentAnonymousId = get();
  if (currentAnonymousId === ANONYMOUS_ID_MISSING_VALUE) {
    const nextAnonymousId = createAnonymousId();
    set(nextAnonymousId);
  }
}

/**
 * Create a new anonymous ID
 *
 * @returns - An anonymous ID string
 */
function createAnonymousId() {
  return uuidv1();
}

export const anonymousId = {
  get,
  set,
  createIfNotExists,
};
