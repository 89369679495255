import {
  FoundPractice,
  OrganizationDataSource,
  OrganizationInput,
  ValidatedPractice,
} from '../../../graphql/types';
import { PracticeSelector } from '../../../components';
import styles from './UpdateOrganization.module.css';
import { useQueryParameters } from '../../../hooks';
import { AddOrganization } from './AddOrganization';
import { useCallback, useMemo, useState } from 'react';
import {
  ActivityIndicator,
  Divider,
  Page,
  Text,
  Toast,
  ToastProps,
  ToastSentiment,
} from '@farmersdog/corgi-x';
import { useValidatePractice } from '../../../graphql/hooks';
import { OrganizationSummary } from './OrganizationSummary/OrganizationSummary';
import { useHistory } from 'react-router';
import * as pagePaths from '../../../constants/pagePaths';
import { validatedOrganizationToInput } from '../../../utils';
import { AddOrganizationFormValues } from './AddOrganization/constants';
import { OrganizationType, countryManualPractice } from '../../../constants';

interface UpdateOrganizationResponsiveProps extends UpdateOrganizationProps {
  isMobile?: boolean;
}

const toastErrorTitle =
  'We were unable to retrieve your practice. Please try again.';
enum PracticeSearchStep {
  Search = 'search',
  Selected = 'selected',
}

const UpdateOrganizationResponsive = ({
  disableSubmit,
  isMobile,
  onUpdateOrganization,
}: UpdateOrganizationResponsiveProps) => {
  const history = useHistory();

  const [step, setStep] = useState<PracticeSearchStep>(
    PracticeSearchStep.Search
  );
  const [selectedPractice, setSelectedPractice] = useState<FoundPractice>();
  const [searchTerm, setSearchTerm] = useState('');
  const [validatedPractice, setValidatedPractice] =
    useState<ValidatedPractice>();
  const [searchResults, setSearchResults] = useState<FoundPractice[]>([]);
  const [toast, setToast] = useState<ToastProps>({
    message: '',
    sentiment: ToastSentiment.Neutral,
    isOpen: false,
  });
  const { validatePractice, loading: validatePracticeLoading } =
    useValidatePractice();

  const params = useQueryParameters();
  const urlPractice = params.get('practice');
  const source =
    urlPractice === 'search'
      ? OrganizationDataSource.Google
      : OrganizationDataSource.User;

  const type =
    urlPractice !== 'none' ? OrganizationType.Practice : OrganizationType.Other;

  const onSelectedPracticeChange = useCallback(
    async (practice: FoundPractice, searchString: string) => {
      setSelectedPractice(practice);
      setSearchTerm(searchString);
      try {
        const { data } = await validatePractice({
          variables: {
            practice: {
              address: practice.formattedAddress,
              name: practice.name,
            },
          },
        });
        if (!data?.validatePractice) {
          setToast({
            message: toastErrorTitle,
            sentiment: ToastSentiment.Negative,
            isOpen: true,
          });
          return;
        }

        setValidatedPractice(data.validatePractice);
        setStep(PracticeSearchStep.Selected);
      } catch {
        setToast({
          message: toastErrorTitle,
          sentiment: ToastSentiment.Negative,
          isOpen: true,
        });
      }
    },
    [validatePractice]
  );

  const onSaveChanges = useCallback(
    (values: AddOrganizationFormValues) => {
      if (!values) return;
      const organization = {
        ...values,
        source,
        type,
        country: countryManualPractice,
        state: values.state!,
      } satisfies OrganizationInput;

      onUpdateOrganization(organization);
    },
    [onUpdateOrganization, source, type]
  );

  const onConfirm = useCallback(() => {
    if (!validatedPractice) return;
    onUpdateOrganization(validatedOrganizationToInput(validatedPractice));
  }, [onUpdateOrganization, validatedPractice]);

  const body = useMemo(() => {
    if (urlPractice === 'none') {
      return (
        <AddOrganization
          onSaveChanges={onSaveChanges}
          disableSubmit={disableSubmit}
        />
      );
    }
    if (urlPractice == 'add') {
      return (
        <AddOrganization
          isAddPractice
          onSaveChanges={onSaveChanges}
          disableSubmit={disableSubmit}
        />
      );
    }
    if (step === PracticeSearchStep.Selected) {
      return (
        <OrganizationSummary
          practice={validatedPractice}
          onBackToSearch={() => {
            setStep(PracticeSearchStep.Search);
          }}
          onConfirm={onConfirm}
          disableSubmit={disableSubmit}
        />
      );
    }

    return (
      <>
        {validatePracticeLoading ? (
          <div className={styles.loadingPractice}>
            <ActivityIndicator mode="dark" />
          </div>
        ) : (
          <PracticeSelector
            selectedPractice={selectedPractice}
            onPracticeSelected={onSelectedPracticeChange}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            setSearchResults={setSearchResults}
            searchResults={searchResults}
            className={styles.practiceSelector}
            withNotAssociated
          />
        )}
      </>
    );
  }, [
    disableSubmit,
    onConfirm,
    onSaveChanges,
    onSelectedPracticeChange,
    searchResults,
    searchTerm,
    selectedPractice,
    step,
    urlPractice,
    validatePracticeLoading,
    validatedPractice,
  ]);
  const onBackClick = () => {
    if (step === PracticeSearchStep.Search && urlPractice === 'search') {
      history.push(pagePaths.PATH_PROFILE);
      return;
    }
    if (step === PracticeSearchStep.Selected) {
      setStep(PracticeSearchStep.Search);
    }
    history.push({
      pathname: pagePaths.PATH_PROFILE,
      search: 'update=practice&practice=search',
    });
  };

  return (
    <Page
      onBackClick={onBackClick}
      onCloseClick={() => history.push(pagePaths.PATH_PROFILE)}
    >
      <Toast
        {...toast}
        autoClose
        onClose={() => setToast({ isOpen: false })}
        timeout={1000}
      />

      <section className={styles.container}>
        <Text
          as="h1"
          variant={isMobile ? 'heading-28' : 'heading-40'}
          bold
          color="blueberry-3"
        >
          Update Your Practice
        </Text>
        <Divider width={1} color="Charcoal0" />

        {body}
      </section>
    </Page>
  );
};

export interface UpdateOrganizationProps {
  onUpdateOrganization: (organization: OrganizationInput) => void;
  disableSubmit: boolean;
}

export const UpdateOrganization = (props: UpdateOrganizationProps) => {
  return (
    <>
      <div className={styles.mobile}>
        <UpdateOrganizationResponsive isMobile {...props} />
      </div>
      <div className={styles.desktop}>
        <UpdateOrganizationResponsive {...props} />
      </div>
    </>
  );
};
