import { Button, Divider, Grid, GridItem, Text } from '@farmersdog/corgi-x';
import classNames from 'classnames';
import { FormFields } from '../../../../components/FormFields';
import styles from './AddVetPractice.module.css';
import { useFormik } from 'formik';
import {
  AddVetPracticeFormValues,
  AddVetpracticeFormFields,
  addVetPracticeInitialFormValues,
  addVetpracticeFieldsData,
} from './constants';
import { generateAddVetPracticeValidationSchema } from './utils';
import { CheckboxWithText } from '../../../CheckboxWithText';
import { TrialDiscountsContainer } from '../TrialDiscountsContainer';

interface AddVetPracticeResponsiveProps {
  isMobile?: boolean;
  onRequestDiscount: (values: AddVetPracticeFormValues) => void;
}

function AddVetPracticeResponsive({
  isMobile = false,
  onRequestDiscount,
}: AddVetPracticeResponsiveProps) {
  const formik = useFormik({
    initialValues: addVetPracticeInitialFormValues,
    validationSchema: generateAddVetPracticeValidationSchema,
    onSubmit: values => onRequestDiscount(values),
  });
  return (
    <TrialDiscountsContainer>
      <form onSubmit={formik.handleSubmit}>
        <Grid>
          <Text
            as="h3"
            variant={isMobile ? 'article-16' : 'article-20'}
            className={styles.title}
            topSpacing="none"
            bottomSpacing={isMobile ? 'md' : 'lg'}
          >
            Please provide details about where you work so that we can verify
            your status as a vet professional. We’ll update your vet profile
            with the details you provide.
          </Text>

          <GridItem
            className={classNames(styles.containerFields, styles.splitForms)}
          >
            <FormFields
              // @ts-expect-error https://github.com/jaredpalmer/formik/issues/2023
              formik={formik}
              fieldData={addVetpracticeFieldsData}
            />
          </GridItem>
          <Text variant="article-16" as="p">
            The discount cannot be used in conjunction with any other
            promotions, offers or discounts. The discount may be rescinded at
            any time, for any reason, in TFD’s sole discretion.
          </Text>
          <Text variant="article-16" as="p" className={styles.removeMargin}>
            The discount available for eligible veterinary professionals and
            support staff under this program can only be applied to purchases of
            The Farmer’s Dog, Inc. products. The discount cannot be redeemed for
            cash, cash equivalents, or any other monetary sum.
          </Text>
          <GridItem>
            <CheckboxWithText
              maxWidth
              checked={formik.values[AddVetpracticeFormFields.acceptedTerms]}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.errors[AddVetpracticeFormFields.acceptedTerms]}
              isTouched={formik.touched[AddVetpracticeFormFields.acceptedTerms]}
              fieldName={AddVetpracticeFormFields.acceptedTerms}
              labelContent={
                <Text variant="article-16" bold>
                  I agree to the above discount terms and conditions
                </Text>
              }
            />
          </GridItem>
          <Divider color="Charcoal0" spacing={isMobile ? 36 : 48} width={1} />
          <GridItem flexDirection="column">
            <Button
              variant="primary"
              type="submit"
              className={styles.requestDiscountBtn}
              disabled={!(formik.isValid && formik.dirty)}
            >
              Request Discount
            </Button>
          </GridItem>
        </Grid>
      </form>
    </TrialDiscountsContainer>
  );
}
export interface AddVetPracticeProps {
  onRequestDiscount: (values: AddVetPracticeFormValues) => void;
}

export const AddVetPractice = ({ onRequestDiscount }: AddVetPracticeProps) => {
  return (
    <div>
      <div className={styles.mobile}>
        <AddVetPracticeResponsive
          isMobile
          onRequestDiscount={onRequestDiscount}
        />
      </div>
      <div className={styles.desktop}>
        <AddVetPracticeResponsive onRequestDiscount={onRequestDiscount} />
      </div>
    </div>
  );
};
