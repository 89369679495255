import { Button, Divider, Grid, GridItem, Text } from '@farmersdog/corgi-x';
import { TrialDiscountsContainer } from '../TrialDiscountsContainer';

import { FormFields } from '../../../../components/FormFields';

import { useFormik } from 'formik';

import {
  enterEmailFormValidationSchema,
  enterEmailFormFieldsData,
  getInitialValues,
} from './assets';

import styles from './EnterEmail.module.css';

interface IsMobileProps {
  isMobile: boolean;
}

interface EnterEmailProps {
  email: string;
  onContinue: (email: string) => void;
}

interface ResponsiveEnterEmailProps extends EnterEmailProps, IsMobileProps {}

const ResponsiveBreakLine = ({ isMobile }: IsMobileProps) => {
  return !isMobile ? <br /> : null;
};

const ResponsiveEnterEmail = ({
  isMobile,
  email,
  onContinue,
}: ResponsiveEnterEmailProps) => {
  const initialValues = getInitialValues({ email });

  const formik = useFormik({
    initialValues,
    validationSchema: enterEmailFormValidationSchema,
    onSubmit: (values, { resetForm }) => {
      onContinue(values.email);
      resetForm();
    },
  });

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    formik.handleSubmit();
  };

  const shouldDisableButton = !formik.isValid || (!formik.dirty && !email);

  return (
    <TrialDiscountsContainer>
      <form onSubmit={handleSubmit}>
        <Grid className={styles.container}>
          <GridItem>
            <Text
              as="p"
              topSpacing="none"
              bottomSpacing="md"
              variant={isMobile ? 'article-16' : 'article-20'}
            >
              Please enter the email address you used
              <ResponsiveBreakLine isMobile={isMobile} /> to sign up for The
              Farmer’s Dog fresh <ResponsiveBreakLine isMobile={isMobile} />{' '}
              food subscription.
            </Text>
          </GridItem>
          <GridItem justifyContent="center" className={styles.formContainer}>
            {/* @ts-expect-error https://github.com/jaredpalmer/formik/issues/2023 */}
            <FormFields fieldData={enterEmailFormFieldsData} formik={formik} />
          </GridItem>
          <GridItem>
            <Divider color="Charcoal0" spacing={isMobile ? 36 : 48} width={1} />
          </GridItem>
          <GridItem>
            <Button
              type="submit"
              className={styles.button}
              aria-disabled={shouldDisableButton}
            >
              Continue
            </Button>
          </GridItem>
        </Grid>
      </form>
    </TrialDiscountsContainer>
  );
};

export const EnterEmail = ({ email, onContinue }: EnterEmailProps) => (
  <>
    <div className={styles.mobile}>
      <ResponsiveEnterEmail email={email} onContinue={onContinue} isMobile />
    </div>
    <div className={styles.desktop}>
      <ResponsiveEnterEmail
        email={email}
        onContinue={onContinue}
        isMobile={false}
      />
    </div>
  </>
);
