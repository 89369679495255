export enum FeatureName {
  HEALTH_VET_PORTAL_BANNER = 'HEALTH_VET_PORTAL_BANNER',
  HEALTH_VET_PORTAL_LUNCH_AND_LEARN_REQUEST = 'HEALTH_VET_PORTAL_LUNCH_AND_LEARN_REQUEST',
  HEALTH_VET_PORTAL_CREDIT_COPY = 'HEALTH_VET_PORTAL_CREDIT_COPY',
  HEALTH_NEW_CONTACT_US_PAGE = 'HEALTH_NEW_CONTACT_US_PAGE',
  HEALTH_VET_PORTAL_REGISTRATION_BANNER = 'HEALTH_VET_PORTAL_REGISTRATION_BANNER',
  HEALTH_VET_PORTAL_SIGNUP_IMPROVEMENT = 'HEALTH_VET_PORTAL_SIGNUP_IMPROVEMENT',
  terms_changes_banner = 'terms_changes_banner',
}

export type AnonymousFeatures = typeof anonymousFeatures;
export type UserFeatures = typeof userFeatures;

export enum OnOffTreatments {
  off = 'off',
  on = 'on',
}

export enum BannerTreatments {
  hide_banner = 'hide_banner',
  control = 'control',
  show_banner = 'show_banner',
}

export interface BannerConfig {
  link: string;
  buttonVerbiage: string;
  textVerbiage: string;
}

export const anonymousFeatures = {
  [FeatureName.terms_changes_banner]: {
    treatments: Object.values(OnOffTreatments),
    track: true,
    mock: { treatment: 'on', config: null },
  },
  [FeatureName.HEALTH_VET_PORTAL_BANNER]: {
    treatments: Object.values(BannerTreatments),
    track: false,
    mock: {
      treatment: 'show_banner',
      config: {
        link: 'https://thefarmersdog.zoom.us/webinar/register/1817137954070/WN_YkN2KoKSR6euO80x82dkuQ',
        buttonVerbiage: 'REGISTER NOW',
        textVerbiage:
          'for a free CE webinar (RACE-approval pending) on May 7th!',
      },
    },
  },
  [FeatureName.HEALTH_VET_PORTAL_REGISTRATION_BANNER]: {
    treatments: Object.values(BannerTreatments),
    track: false,
    mock: {
      treatment: 'on',
      config: null,
    },
  },
  [FeatureName.HEALTH_VET_PORTAL_SIGNUP_IMPROVEMENT]: {
    treatments: Object.values(OnOffTreatments),
    track: false,
    mock: {
      treatment: 'on',
      config: null,
    },
  },
};

export const userFeatures = {
  [FeatureName.HEALTH_VET_PORTAL_LUNCH_AND_LEARN_REQUEST]: {
    treatments: Object.values(OnOffTreatments),
    track: false,
    mock: {
      treatment: 'on',
      config: null,
    },
  },
  [FeatureName.HEALTH_VET_PORTAL_CREDIT_COPY]: {
    treatments: Object.values(OnOffTreatments),
    track: false,
    mock: {
      treatment: 'on',
      config: null,
    },
  },
  [FeatureName.HEALTH_NEW_CONTACT_US_PAGE]: {
    treatments: Object.values(OnOffTreatments),
    track: false,
    mock: {
      treatment: 'on',
      config: null,
    },
  },
};
