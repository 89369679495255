import { useCallback, useMemo, useState } from 'react';
import { Redirect } from 'react-router';
import { useVetDetails } from '../../graphql/hooks/useVetDetails';
import omit from 'lodash/omit';

import {
  ActivityIndicator,
  Grid,
  Page,
  Toast,
  ToastProps,
  ToastSentiment,
} from '@farmersdog/corgi-x';
import {
  AddVetPractice,
  AreYouSubscribed,
  EnterEmail,
  HowToGetDiscount,
  PracticeSearch,
  ReviewInfo,
  RequestSuccess,
} from './components';

import {
  FoundPractice,
  OrganizationDataSource,
  OrganizationInput,
  OrganizationView,
  Position,
  VetInput,
} from '../../graphql/types';
import * as pagePaths from '../../constants/pagePaths';

import styles from './TrialDiscountsRevamp.module.css';
import { DiscountRequestState, useDiscountRequestFlow } from '../../hooks';
import { AddVetPracticeFormValues } from './components/AddVetPractice/constants';
import { ReviewInfoFormValues } from './components/ReviewInfo/assets';
import { countryManualPractice, OrganizationType } from '../../constants';
import { useAddDiscountRequest, useUpdateVet } from '../../graphql/hooks';
import { getErrorMessage } from '../../services/auth/utils/errors';

export function TrialDiscountsRevamp() {
  const [organization, setOrganization] = useState<OrganizationInput>();
  const [position, setPosition] = useState<Position>();
  const [isCompleteVerification, setIsCompleteVerification] =
    useState<boolean>(false);
  const [toast, setToast] = useState<ToastProps>({
    message: '',
    sentiment: ToastSentiment.Neutral,
    isOpen: false,
  });
  const [email, setEmail] = useState('');
  const [selectedPractice, setSelectedPractice] = useState<FoundPractice>();
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState<FoundPractice[]>([]);

  const { data, loading, called } = useVetDetails();
  const [addDiscountRequest] = useAddDiscountRequest();
  const [updateVet] = useUpdateVet();

  const currentOrganization = data?.vetDetails?.organization;
  const currentVetData = data?.vetDetails?.vet;
  const hospitalResponse = currentVetData?.hospital;
  const positionResponse = currentVetData?.position;
  const isLegacyHospital = Boolean(!currentOrganization && hospitalResponse);

  const {
    onClose,
    onAlreadySubscribed,
    onNotYetSubscribed,
    isState,
    onPrevious,
    onEnterEmailContinue,
    onUpdatePractice,
    onSelectPractice,
    onSubmit,
    onHowToGetDiscountContinue,
    onAddPractice,
  } = useDiscountRequestFlow({ isLegacyHospital: isLegacyHospital });

  if (!position && positionResponse && called) {
    setPosition(positionResponse);
  }
  if (!organization && currentOrganization && called) {
    setOrganization(organizationToInput(currentOrganization));
  }

  const handleIsCompleteVerification = () => {
    setIsCompleteVerification(true);
  };

  const onContinue = useCallback(
    (registeredEmail: string) => {
      setEmail(registeredEmail);
      onEnterEmailContinue();
    },
    [onEnterEmailContinue]
  );

  const onRequestDiscount = useCallback(
    async ({
      organizationInput,
      reviewInfoValues,
    }: {
      organizationInput: OrganizationInput;
      reviewInfoValues?: ReviewInfoFormValues;
    }) => {
      try {
        if (!currentVetData) {
          return;
        }
        await addDiscountRequest({
          variables: {
            input: {
              acceptedTerms: reviewInfoValues!.acceptedPrivacyPolicy,
              email: email,
              firstName: currentVetData.firstName ?? '',
              lastName: currentVetData.lastName ?? '',
            },
          },
        });

        const vet = omit(currentVetData, [
          'discountRequests',
          '__typename',
          'hospital',
          'id',
        ]);

        if (!reviewInfoValues) {
          return;
        }

        await updateVet({
          variables: {
            vetInput: {
              ...(vet as VetInput),
              acceptedPrivacyPolicy: reviewInfoValues.acceptedPrivacyPolicy,
              position: reviewInfoValues?.position
                ? (reviewInfoValues?.position as Position)
                : position!,
            },
            organizationInput,
          },
        });
        onSubmit();
      } catch (error) {
        const message = getErrorMessage(error);
        setToast({
          message,
          sentiment: ToastSentiment.Negative,
          isOpen: true,
        });
      }
    },
    [addDiscountRequest, currentVetData, email, onSubmit, position, updateVet]
  );

  const handleSelectedOrganizationChange = useCallback(
    (newOrganization: OrganizationInput) => {
      setOrganization(newOrganization);

      onSelectPractice();
    },
    [onSelectPractice]
  );

  const step = useMemo(() => {
    if (isState(DiscountRequestState.Close)) {
      return <Redirect to={pagePaths.PATH_DASHBOARD} />;
    }

    if (isState(DiscountRequestState.AreYouSubscribed)) {
      return (
        <AreYouSubscribed
          onAlreadySubscribed={onAlreadySubscribed}
          onNotYetSubscribed={onNotYetSubscribed}
        />
      );
    }

    if (isState(DiscountRequestState.EnterEmail)) {
      return <EnterEmail email={email} onContinue={onContinue} />;
    }

    if (isState(DiscountRequestState.HowToGetDiscount)) {
      return (
        <HowToGetDiscount
          isCompleteVerification={isCompleteVerification}
          handleIsCompleteVerification={handleIsCompleteVerification}
          handleContinue={onHowToGetDiscountContinue}
        />
      );
    }

    if (isState(DiscountRequestState.PracticeSearch)) {
      return (
        <PracticeSearch
          onAddVetPractice={onAddPractice}
          handleSelectedOrganizationChange={handleSelectedOrganizationChange}
          selectedPractice={selectedPractice}
          searchTerm={searchTerm}
          searchResults={searchResults}
          setSelectedPractice={setSelectedPractice}
          setSearchResults={setSearchResults}
          setSearchTerm={setSearchTerm}
        />
      );
    }

    if (isState(DiscountRequestState.ReviewInfo)) {
      return (
        <ReviewInfo
          onUpdate={onUpdatePractice}
          position={position}
          organization={organizationToInput(currentOrganization)}
          onRequestDiscount={values =>
            onRequestDiscount({
              organizationInput: organizationToInput(currentOrganization),
              reviewInfoValues: values,
            })
          }
        />
      );
    }

    if (isState(DiscountRequestState.ReviewInfoWithUpdatedPractice)) {
      return (
        <ReviewInfo
          isUpdate
          onUpdate={onUpdatePractice}
          position={position}
          organization={organization}
          onRequestDiscount={values =>
            onRequestDiscount({
              reviewInfoValues: values,
              organizationInput: organization!,
            })
          }
        />
      );
    }

    if (isState(DiscountRequestState.AddPractice)) {
      return (
        <AddVetPractice
          onRequestDiscount={values =>
            onRequestDiscount({
              reviewInfoValues: {
                acceptedPrivacyPolicy: values.acceptedTerms,
                position: values.position,
              },
              organizationInput: addVetPracticeFormValuesToInput(values),
            })
          }
        />
      );
    }

    if (isState(DiscountRequestState.RequestSuccess)) {
      return <RequestSuccess onReturnToVetPortal={onClose} />;
    }
    return null;
  }, [
    currentOrganization,
    email,
    handleSelectedOrganizationChange,
    isCompleteVerification,
    isState,
    onAddPractice,
    onAlreadySubscribed,
    onClose,
    onContinue,
    onHowToGetDiscountContinue,
    onNotYetSubscribed,
    onRequestDiscount,
    onUpdatePractice,
    organization,
    position,
    searchResults,
    searchTerm,
    selectedPractice,
  ]);

  if (loading)
    return (
      <Grid className={styles.loadingContainer}>
        <ActivityIndicator mode="dark" />
      </Grid>
    );

  return (
    <Page
      layout="base"
      onCloseClick={onClose}
      onBackClick={
        isState(DiscountRequestState.AreYouSubscribed) ||
        isState(DiscountRequestState.RequestSuccess)
          ? undefined
          : onPrevious
      }
      className={styles.page}
    >
      {step}
      <Toast {...toast} autoClose onClose={() => setToast({ isOpen: false })} />
    </Page>
  );
}

function organizationToInput(
  organization: OrganizationView | null | undefined
): OrganizationInput {
  if (!organization) return {} as OrganizationInput;
  return {
    id: organization.id,
    name: organization.name,
    phone: organization.phone,
    source: organization.source,
    state: organization.state,
    streetAddress1: organization.streetAddress1,
    streetAddress2: organization.streetAddress2,
    type: organization.type,
    zip: organization.zip,
    city: organization.city,
    country: organization.country,
  };
}

function addVetPracticeFormValuesToInput(
  manualOrganization: AddVetPracticeFormValues
): OrganizationInput {
  return {
    city: manualOrganization.city,
    name: manualOrganization.name,
    phone: manualOrganization.phone,
    state: manualOrganization.state!,
    source: OrganizationDataSource.User,
    zip: manualOrganization.zip,
    streetAddress1: manualOrganization.streetAddress1,
    streetAddress2: manualOrganization.streetAddress2,
    type: OrganizationType.Practice,
    country: countryManualPractice,
  };
}
