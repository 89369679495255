import { useFeature } from '@farmersdog/ab-testing';
import {
  AnonymousFeatures,
  UserFeatures,
  FeatureName,
  OnOffTreatments,
} from '../../features';

export function useImprovedSignUp() {
  const { treatment } = useFeature<
    AnonymousFeatures,
    UserFeatures,
    FeatureName
  >(FeatureName.HEALTH_VET_PORTAL_SIGNUP_IMPROVEMENT, {});

  const showImprovedSignUpPage = treatment === OnOffTreatments.on;

  return {
    showImprovedSignUpPage,
  };
}
