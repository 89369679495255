import { useGlobalLightbox, LightboxId } from '../../../../hooks';

import { Button, Divider, Grid, GridItem, Text } from '@farmersdog/corgi-x';
import { TrialDiscountsContainer } from '../TrialDiscountsContainer';
import { TermsModal } from '../TermsModal';

import DogEatingSVG from './assets/DogEating.svg?react';

import styles from './AreYouSubscribed.module.css';

interface ResponsiveAreYouSubscribedProps {
  isMobile: boolean;
  onNotYetSubscribed: () => void;
  onAlreadySubscribed: () => void;
}

function ResponsiveAreYouSubscribed({
  isMobile,
  onAlreadySubscribed,
  onNotYetSubscribed,
}: ResponsiveAreYouSubscribedProps) {
  const { open: openTermsModal } = useGlobalLightbox({
    id: LightboxId.AreYouSubscribedTerms,
  });

  return (
    <TrialDiscountsContainer withSubtitle>
      <Grid
        columnGap="lg"
        flexDirection="column"
        className={styles.container}
        bottomSpacing={{
          xs: 'lg',
          lg: 'none',
        }}
      >
        <GridItem xs={12}>
          <div className={styles.dogSvg}>
            <DogEatingSVG />
          </div>
          <Text
            as="p"
            topSpacing="lg"
            bottomSpacing="none"
            variant={isMobile ? 'article-16' : 'article-20'}
          >
            Do you have a fresh-food subscription to {!isMobile && <br />} The
            Farmer’s Dog?
          </Text>
        </GridItem>
        <GridItem>
          <Divider color="Charcoal0" spacing={isMobile ? 36 : 48} width={1} />
        </GridItem>
        <GridItem className={styles.buttons}>
          <Grid
            gap="sm"
            justifyContent="center"
            flexDirection={{
              xs: 'column',
              lg: 'row',
            }}
          >
            <GridItem xs={12} lg={5}>
              <Button
                className={styles.button}
                variant="primary"
                onClick={onAlreadySubscribed}
              >
                Yes, I’ve Subscribed
              </Button>
            </GridItem>
            <GridItem xs={12} lg={5}>
              <Button
                className={styles.button}
                variant="primary"
                onClick={onNotYetSubscribed}
              >
                No, I’m New Here
              </Button>
            </GridItem>
          </Grid>
        </GridItem>
        <Button
          variant="plain-text"
          className={styles.termsButton}
          onClick={() => openTermsModal()}
        >
          <Text
            bold
            as="a"
            color="kale-2"
            variant="heading-16"
            className={styles.termsText}
          >
            *Terms and conditions apply
          </Text>
        </Button>
      </Grid>
    </TrialDiscountsContainer>
  );
}

interface AreYouSubscribedProps {
  onAlreadySubscribed: () => void;
  onNotYetSubscribed: () => void;
}

export function AreYouSubscribed({
  onAlreadySubscribed,
  onNotYetSubscribed,
}: AreYouSubscribedProps) {
  return (
    <>
      <div className={styles.mobile}>
        <ResponsiveAreYouSubscribed
          isMobile
          onAlreadySubscribed={onAlreadySubscribed}
          onNotYetSubscribed={onNotYetSubscribed}
        />
      </div>
      <div className={styles.desktop}>
        <ResponsiveAreYouSubscribed
          isMobile={false}
          onAlreadySubscribed={onAlreadySubscribed}
          onNotYetSubscribed={onNotYetSubscribed}
        />
      </div>
      <TermsModal />
    </>
  );
}
