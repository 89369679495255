import { Ga4VetPortalEvent, mountGa4Event } from '@farmersdog/pixels';
import { getGa4EventConfig } from './getGa4EventConfig';
import config from '../../config';

export function trackGa4SignupSuccessEvent() {
  if (!config.get('app.ga4.enabled')) {
    return;
  }
  const properties = {};
  mountGa4Event({
    name: Ga4VetPortalEvent.SignupSuccess,
    properties,
    config: getGa4EventConfig({ sendTo: 'vetPortal' }),
  });
}
