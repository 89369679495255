import { useEffect } from 'react';
import * as pagePaths from '../constants/pagePaths';
import { useHistory } from 'react-router';

interface useSignupSuccessProps {
  isAuthenticated?: boolean;
  isConferenceModeOn: boolean;
  logout: (() => Promise<void>) | undefined;
}
export const useSignupSuccess = ({
  isAuthenticated,
  isConferenceModeOn,
  logout,
}: useSignupSuccessProps) => {
  const history = useHistory();
  useEffect(() => {
    if (isAuthenticated) {
      if (isConferenceModeOn && logout) {
        void logout();
      } else {
        history.push(pagePaths.PATH_DASHBOARD);
      }
    }
  }, [isAuthenticated, history, isConferenceModeOn, logout]);
};
