import { createContext, useContext } from 'react';
import { BannerConfig } from '../features';

export type PublicPageFeaturesProps = {
  showPortalBanner: boolean;
  bannerConfig: BannerConfig | null;
  showSignUpBanner: boolean;
  showImprovedSignUpPage: boolean;
  showTermsChangesBanner: boolean;
};

export const PublicPageFeaturesContext = createContext<
  Partial<PublicPageFeaturesProps>
>({});

export const usePublicPageFeatures = () => {
  const context = useContext(PublicPageFeaturesContext);
  if (context === undefined) {
    throw new Error(
      'usePublicPageFeatures must be used with PublicPageFeaturesProvider'
    );
  } else {
    return context;
  }
};
