import config from '../../config';
/**
 * Rename the key of cookie to avoid the ajs_user_id from the core website
 * being reused as the user_id for the segment on Vet-Portal
 */
const userSetting = {
  user: {
    cookie: {
      key: 'ajs_vet-portal_user_id',
    },
  },
};
const segmentKey = config.get('app.segment.key');
const segmentEnabled = config.get('app.segment.enabled');

export const segmentScriptContent =
  `!function(){var analytics=window.analytics=window.analytics||[];` +
  `if(!analytics.initialize)if(analytics.invoked)window.console&&console.error` +
  `&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;` +
  `analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview",` +
  `"identify","reset","group","track","ready","alias","debug","page","once","off","on",` +
  `"addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];` +
  `analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);` +
  `t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++)` +
  `{var key=analytics.methods[e];analytics[key]=analytics.factory(key)}analytics.load=function(key,e)` +
  `{var t=document.createElement("script");t.type="text/javascript";` +
  `t.async=!0;t.src="https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";` +
  `var n=document.getElementsByTagName("script")[0];` +
  `n.parentNode.insertBefore(t,n);analytics._loadOptions=e};analytics._writeKey="${segmentKey}";` +
  `;analytics.SNIPPET_VERSION="4.15.3";analytics.load("${segmentKey}",${JSON.stringify(userSetting)});}}();`;

export function segmentScript() {
  if (!segmentKey || !segmentEnabled) {
    return '';
  }

  return `<script>${segmentScriptContent}</script>`;
}
