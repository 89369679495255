import { useFeature } from '@farmersdog/ab-testing';
import {
  AnonymousFeatures,
  UserFeatures,
  FeatureName,
  OnOffTreatments,
} from './features';

export function useCreditCopy() {
  const { treatment } = useFeature<
    AnonymousFeatures,
    UserFeatures,
    FeatureName
  >(FeatureName.HEALTH_VET_PORTAL_CREDIT_COPY, {});

  const showCreditCopy = treatment === OnOffTreatments.on;

  return {
    showCreditCopy,
  };
}
