export enum PetNodes {
  ActivityLevel = 'activityLevel',
  BirthdayAmount = 'birthdayAmount',
  BirthdayUnit = 'birthdayUnit',
  BodyCondition = 'bodyCondition',
  Breeds = 'breeds',
  EatingStyle = 'eatingStyle',
  FoodType = 'foodType',
  FoodBrand = 'foodBrand',
  Gender = 'gender',
  Healthy = 'healthy',
  Issues = 'issues',
  Name = 'name',
  Nature = 'nature',
  Neutered = 'neutered',
  PetAgeSkipButton = 'petAgeSkipButton',
  Health = 'PetHealth',
  PrescriptionDiet = 'prescriptionDiet',
  PrescriptionDiets = 'prescriptionDiets',
  TargetWeight = 'targetWeight',
  TreatsQuantity = 'treatsQuantity',
  Weight = 'weight',
  FreshSelection = 'freshSelection',
  GrainsPreference = 'grainsPreference',
}

export enum LeadNodes {
  Email = 'email',
  FirstName = 'firstName',
  FreshFoodConfidence = 'freshFoodConfidence',
  NumPets = 'numPets',
  Phone = 'phone',
  PhoneConsent = 'phoneConsent',
  Zip = 'zip',
}

export enum ErrorNodes {
  Me1Error = 'me1Error',
  ZipError = 'zipError',
  EmailError = 'emailError',
  PhoneError = 'phoneError',
  PetAgeError = 'petAgeError',
  PetWeightError = 'petWeightError',
  AuthRegisterError = 'authRegisterError',
}

export const NodeNames = { ...LeadNodes, ...PetNodes };
export type NodeName = (typeof NodeNames)[keyof typeof NodeNames];

export const InlineErrorNodeNames = { ...ErrorNodes };
export type InlineErrorNodeName =
  (typeof InlineErrorNodeNames)[keyof typeof InlineErrorNodeNames];
