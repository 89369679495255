import { Picture } from '../../../components/Picture';
import { Grid, GridItem, Text, PageWidth, Nowrap } from '@farmersdog/corgi-x';

import { foodBowlAssets, foodBowlMobileAssets } from './assets';

import styles from './WhatFreshFoodIsSection.module.css';
import { SectionId } from '../Hero/nav-items';

interface ResponsiveWhatFreshFoodIsSectionProps {
  isMobile?: boolean;
}

type WhatFreshFoodIsContentProps = ResponsiveWhatFreshFoodIsSectionProps;

export function WhatFreshFoodIsContent({
  isMobile,
}: WhatFreshFoodIsContentProps) {
  return (
    <GridItem className={styles.infoContainer}>
      <div className={styles.textsContainer}>
        <Text
          bold
          as="h2"
          vSpacing="none"
          variant={isMobile ? 'heading-28' : 'heading-40'}
          color="blueberry-3"
        >
          What The Farmer’s
          {!isMobile && <br />} Dog Fresh Food Is
        </Text>
        <Text variant="article-16" color="charcoal-3">
          While not all fresh dog food is the same, The Farmer’s Dog recipes are
          made from whole meats and vegetables, cooked to destroy pathogens but
          retain the nutritional value and moisture of
          <Nowrap> the ingredients.</Nowrap>
        </Text>
        <Text variant="article-16" color="charcoal-3">
          Our food is made to the safety and quality standards of human-food,
          according to FDA regulations. That human-grade designation means that
          the ingredients used to make the food, and the facility where the food
          is made, meet the same safety and quality standards that govern the
          food <Nowrap>humans eat.</Nowrap>
        </Text>
        <Text variant="article-16" color="charcoal-3">
          All of our recipes were developed and reviewed by{' '}
          <strong> board-certified nutritionists. </strong>They are complete and
          balanced according to <Nowrap>AAFCO standards.</Nowrap>
        </Text>
      </div>
    </GridItem>
  );
}

export function ResponsiveWhatFreshFoodIsSection({
  isMobile,
}: ResponsiveWhatFreshFoodIsSectionProps) {
  return (
    <Grid
      className={styles.container}
      justifyContent={{ xs: 'flex-start', lg: 'space-between' }}
      flexDirection={{ xs: 'column-reverse', lg: 'row' }}
    >
      {isMobile ? (
        <PageWidth>
          <WhatFreshFoodIsContent isMobile />
        </PageWidth>
      ) : (
        <WhatFreshFoodIsContent />
      )}

      <GridItem className={styles.foodImageContainer}>
        <Picture
          className={styles.foodImage}
          sources={isMobile ? foodBowlMobileAssets : foodBowlAssets}
          alt="Food bowl"
        />
      </GridItem>
    </Grid>
  );
}

export function WhatFreshFoodIsSection() {
  return (
    <section className={styles.section} id={SectionId.WhyFresh}>
      <div className={styles.mobile}>
        <ResponsiveWhatFreshFoodIsSection isMobile />
      </div>
      <div className={styles.desktop}>
        <ResponsiveWhatFreshFoodIsSection />
      </div>
    </section>
  );
}
