import { AppBarProps } from '@farmersdog/corgi';
import * as pagePaths from '../../constants/pagePaths';
import config from '../../config';
import { trackLandingPageClickedRegisterForVetTeamPortalStickyNavbar } from '../../analytics/events';

interface GetMobileNavigationItemsArgs {
  loginUrl: string;
}

type GetTitleNavigationItemsArgs = GetMobileNavigationItemsArgs;

export const getMobileNavigationItems = ({
  loginUrl,
}: GetMobileNavigationItemsArgs): AppBarProps => ({
  leftItems: [
    {
      title: 'Log In',
      to: loginUrl,
    },
  ],
  callToAction: {
    title: 'Register for Vet Team Portal',
    to: pagePaths.PATH_SIGNUP,
    onClick: () => {
      trackLandingPageClickedRegisterForVetTeamPortalStickyNavbar();
    },
  },
  logoHref: config.get('app.website.url'),
});

export const getTitleNavigationItems = ({
  loginUrl,
}: GetTitleNavigationItemsArgs): AppBarProps => ({
  leftItems: [
    {
      title: 'For Vet Professionals',
      href: pagePaths.PATH_HOME,
    },
  ],
  callToAction: {
    title: 'Register for Vet Team Portal',
    to: pagePaths.PATH_SIGNUP,
    onClick: () => {
      trackLandingPageClickedRegisterForVetTeamPortalStickyNavbar();
    },
  },
  persistentItem: {
    title: 'Log In',
    to: loginUrl,
  },
  logoHref: config.get('app.website.url'),
});
