import { AccountPage } from '../../components/AccountPage';
import { Button, Divider, Grid, Text, VideoSection } from '@farmersdog/corgi-x';
import styles from './RecordedEvent.module.css';
import { Chevron } from '@farmersdog/corgi-x/icons';
import * as pagePaths from '../../constants/pagePaths';
import { Card, Picture } from '../../components';
import { MouseEventHandler, useMemo, useState } from 'react';
import { getEventsAndRecordingsData } from '../../data/eventsAndRecordingsData';
import { LightboxId, useGlobalLightbox, useQueryParameters } from '../../hooks';
import { SelectedEvent, ShareModal } from './SharedModal';
import { trackWebinarsPageClickedShareButton } from '../../analytics/events';
import { useCreditCopy } from '../../abTesting';
import classNames from 'classnames';
import { joinText } from '../../utils/joinText';
import { useScreenSizeContext } from '../../context/ScreenSizeContext';

const CREDIT_COPY =
  'To earn RACE Credit, watch the complete video and then click the "Start Quiz" button that appears in the video player.';

export const RecordedEvent = () => {
  const [selectedWebinar, setSelectedWebinar] = useState<SelectedEvent>();

  const { isMobile } = useScreenSizeContext();

  const query = useQueryParameters();
  const id = query.get('videoId');
  const { showCreditCopy } = useCreditCopy();
  const { open: openShareModal } = useGlobalLightbox({
    id: LightboxId.ShareWebinar,
  });

  const event = useMemo(
    () =>
      getEventsAndRecordingsData({ showCreditCopy }).find(
        recordedEvent => recordedEvent.videoId === id
      ),
    [id, showCreditCopy]
  );

  const handleShareButtonClick: MouseEventHandler<HTMLButtonElement> = () => {
    setSelectedWebinar(event);
    openShareModal();
    trackWebinarsPageClickedShareButton({ title: event?.videoTitle ?? '' });
  };

  const videoDataContainerClassnames = classNames(styles.videoDataContainer, {
    [styles.addMarginBottom]: showCreditCopy,
  });

  const getNames = (speakers: string[]) => {
    return joinText({
      elements: speakers,
      separator: ', ',
      endSeparator: ' & ',
    });
  };

  return (
    <>
      <AccountPage>
        <Button
          variant="plain-text"
          type="link"
          to={pagePaths.PATH_EVENTS_AND_RECORDINGS}
          className={styles.link}
          color="kale-dark"
          weight="normal"
        >
          Events & Recordings
          <Chevron
            orientation="right"
            height={20}
            aria-hidden
            baseColor="Charcoal0"
          />
        </Button>

        {event ? (
          <Grid>
            <Text
              as="h2"
              variant={isMobile ? 'heading-28' : 'heading-40'}
              bold
              topSpacing="none"
              color="blueberry-3"
            >
              {event.videoTitle}
            </Text>
            <Grid className={styles.info}>
              <Card>
                <VideoSection
                  className={styles.video}
                  wistiaOptions={event?.wistiaOptions}
                  wistiaId={event.videoId}
                />
                <div className={videoDataContainerClassnames}>
                  <div>
                    <Text
                      as="h4"
                      variant="heading-16"
                      bold
                      color="charcoal-3"
                      topSpacing={'sm'}
                      bottomSpacing={'none'}
                    >
                      {getNames(
                        event.speakers.map(speaker => speaker.nameAndTitle)
                      )}
                    </Text>
                    <Text
                      as="h4"
                      variant="heading-16"
                      color="charcoal-2"
                      topSpacing={'none'}
                      bottomSpacing={isMobile ? undefined : 'none'}
                    >
                      Recorded on {event.webinarDate}
                    </Text>
                  </div>
                  <div>
                    <Button
                      onClick={handleShareButtonClick}
                      variant="primary-mini"
                      className={styles.shareButton}
                      aria-label={`share ${event.videoTitle} video`}
                      name={event.videoId}
                    >
                      Share
                    </Button>
                  </div>
                </div>
                {showCreditCopy && (
                  <>
                    <Text as="span" variant="article-16" color="charcoal-3">
                      {CREDIT_COPY}
                    </Text>
                    <Divider width={1} color="Kale1" spacing={24} />
                  </>
                )}
                <Text variant="article-16" color="charcoal-3">
                  {event.webinarDescription}
                </Text>
              </Card>
              {event.speakers.map(speaker => (
                <Card key={`${speaker.nameAndTitle}-${speaker.position}`}>
                  <div className={styles.speakerContainer}>
                    <div className={styles.speakerImageContainer}>
                      <Picture
                        width="72"
                        height="72"
                        sources={speaker.imageSources}
                        alt={speaker.imageAlt}
                      />
                    </div>
                    <div className={styles.speaker}>
                      <Text
                        as="h4"
                        variant="heading-16"
                        color="blueberry-3"
                        topSpacing={'none'}
                        bottomSpacing={'none'}
                        bold
                      >
                        About {speaker.nameAndTitle}
                      </Text>
                      <Text
                        as="h4"
                        variant="heading-16"
                        color="charcoal-3"
                        topSpacing={'xs'}
                        bottomSpacing={'none'}
                      >
                        {speaker.position}
                      </Text>
                      <Text
                        as="p"
                        variant="article-16"
                        color="charcoal-3"
                        topSpacing={'sm'}
                        bottomSpacing={'none'}
                      >
                        {speaker.description}
                      </Text>
                    </div>
                  </div>
                </Card>
              ))}
            </Grid>
          </Grid>
        ) : (
          <Text
            as="h2"
            variant={isMobile ? 'heading-28' : 'heading-40'}
            bold
            topSpacing="none"
            color="blueberry-3"
          >
            Event not found
          </Text>
        )}
      </AccountPage>
      <ShareModal selectedEvent={selectedWebinar} />
    </>
  );
};
