import * as yup from 'yup';
import { ContactUsFormFields, contactReason } from './contactUsFormFieldsData';
import { EMAIL_REGEXP } from '../../constants/validation';
import { noTrailingWhitespace } from '../../utils/validation/no-trailing-whitespace';
import { fieldRequiredErrorMessage } from '../../utils';

export const contactUsFormValidationSchema = yup.object({
  [ContactUsFormFields.firstName]: yup
    .string()
    .required(fieldRequiredErrorMessage),
  [ContactUsFormFields.lastName]: yup
    .string()
    .required(fieldRequiredErrorMessage),
  [ContactUsFormFields.phone]: yup.string(),
  [ContactUsFormFields.email]: yup
    .string()
    .required(fieldRequiredErrorMessage)
    .test('trailing whitespace', 'Remove spaces', noTrailingWhitespace)
    .matches(EMAIL_REGEXP, 'Invalid email address'),
  [ContactUsFormFields.contactReason]: yup
    .string()
    .required(fieldRequiredErrorMessage)
    .oneOf(contactReason),
  [ContactUsFormFields.message]: yup
    .string()
    .required(fieldRequiredErrorMessage)
    .max(500, 'Message must be less than 500 characters'),
});
