import { useMemo } from 'react';
import { useQueryParameters } from './useQueryParameters';

/**
 * Custom hook that returns the url from the deeplink. It gets it from the 'next' query parameter.
 * Usually used in buttons or links that redirect to pages that will need the deeplink after performing an task.
 */
export function useDeepLink() {
  const query = useQueryParameters();

  const redirectTo = useMemo(() => {
    const newQuery = new URLSearchParams();
    const next = query.get('next');
    if (next) {
      newQuery.set('next', next);
    }
    return newQuery.toString();
  }, [query]);
  return { redirectTo };
}
