import { getMainSiteUserId } from './cookies';
// explicitly allowing the import of identify from @farmersdog/analytics in this file
// eslint-disable-next-line no-restricted-imports
import { identify as segmentIdentify } from '@farmersdog/analytics';
import config from '../config';

interface IdentifyProps {
  userId: string;
  identityTraits?: Record<string, unknown>;
}

export function identify({ userId, identityTraits = {} }: IdentifyProps) {
  if (!config.get('app.segment.enabled')) {
    return;
  }
  const main_site_user_id = getMainSiteUserId();
  segmentIdentify(userId, {
    main_site_user_id,
    ...identityTraits,
  });
}
