import { Text, TextProps } from '@farmersdog/corgi-x';
import styles from './PageTitle.module.css';

const mobileTitleVariant = 'heading-28';
const mobileSubtitleVariant = 'article-16';

const desktopTitleVariant = 'heading-52';
const desktopSubtitleVariant = 'article-20';

interface PageTitleProps {
  title: string;
  subtitle: string;
  mobileTitleMaxWidth?: number;
  mobileSubtitleMaxWidth?: number;
  desktopTitleMaxWidth?: number;
  desktopSubtitleMaxWidth?: number;
}

interface TitleAndSubtitlePairProps {
  title: PageTitleProps['title'];
  subtitle: PageTitleProps['subtitle'];
  variants: {
    title: TextProps['variant'];
    subtitle: TextProps['variant'];
  };
  titleMaxWidth?: number;
  subtitleMaxWidth?: number;
}

function TitleAndSubtitlePair({
  title,
  subtitle,
  variants,
  titleMaxWidth,
  subtitleMaxWidth,
}: TitleAndSubtitlePairProps) {
  return (
    <div className={styles.titlesContainer}>
      <Text
        className={styles.title}
        style={titleMaxWidth ? { maxWidth: `${titleMaxWidth}px` } : undefined}
        color="charcoal-3"
        as="h1"
        bold
        variant={variants.title}
      >
        {title}
      </Text>
      <Text
        className={styles.subtitle}
        style={
          subtitleMaxWidth ? { maxWidth: `${subtitleMaxWidth}px` } : undefined
        }
        color="charcoal-3"
        as="h2"
        variant={variants.subtitle}
      >
        {subtitle}
      </Text>
    </div>
  );
}

export function PageTitle(props: PageTitleProps) {
  const defaultProps = { title: props.title, subtitle: props.subtitle };
  return (
    <>
      <div className={styles.mobile}>
        <TitleAndSubtitlePair
          {...defaultProps}
          titleMaxWidth={props.mobileTitleMaxWidth}
          subtitleMaxWidth={props.mobileSubtitleMaxWidth}
          variants={{
            title: mobileTitleVariant,
            subtitle: mobileSubtitleVariant,
          }}
        />
      </div>
      <div className={styles.desktop}>
        <TitleAndSubtitlePair
          {...defaultProps}
          titleMaxWidth={props.desktopTitleMaxWidth}
          subtitleMaxWidth={props.desktopSubtitleMaxWidth}
          variants={{
            title: desktopTitleVariant,
            subtitle: desktopSubtitleVariant,
          }}
        />
      </div>
    </>
  );
}
