import * as React from 'react';

import { Text, Link, Divider } from '@farmersdog/corgi-x';
import {
  therapeutic,
  weightManagement,
  petFoodProcessing,
} from './additionalListData';

import { trackDownloadableInfo } from '../../../analytics/events';

import styles from './AdditionalList.module.css';

const sections = [
  {
    title: 'Therapeutic',
    dataList: therapeutic,
  },
  {
    title: 'Weight Management',
    dataList: weightManagement,
  },
  {
    title: 'Pet Food Processing',
    dataList: petFoodProcessing,
  },
];

export function AdditionalList() {
  return (
    <>
      <div className={styles.container}>
        {sections.map(section => {
          return (
            <React.Fragment key={section.title}>
              <Divider width={1} color="Charcoal0" spacing={26} />
              <Text as="h4" variant="heading-22" bold color="blueberry-3">
                {section.title}
              </Text>
              <ul className={styles.list}>
                {section.dataList.map(item => {
                  return (
                    <li
                      key={item.title}
                      className={styles.listItem}
                      aria-label={`Link to ${item.title}`}
                    >
                      <div>
                        <Text
                          variant="heading-16"
                          bold
                          className={styles.itemTitle}
                        >
                          {item.title}
                        </Text>
                        <Text variant="heading-12">
                          <Link
                            target="_blank"
                            aria-label={`View ${item.title} Article`}
                            rel="noopener noreferrer"
                            href={item.link}
                            onClick={() =>
                              trackDownloadableInfo({ title: item.title })
                            }
                            className={styles.link}
                          >
                            View Article
                          </Link>
                        </Text>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </React.Fragment>
          );
        })}
      </div>
    </>
  );
}
