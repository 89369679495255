import { useFeature } from '@farmersdog/ab-testing';
import {
  AnonymousFeatures,
  UserFeatures,
  FeatureName,
  OnOffTreatments,
} from '../../features';

export function useNewContactUsPage() {
  const { treatment } = useFeature<
    AnonymousFeatures,
    UserFeatures,
    FeatureName
  >(FeatureName.HEALTH_NEW_CONTACT_US_PAGE, {});

  const showNewContactUsPage = treatment === OnOffTreatments.on;

  return {
    showNewContactUsPage,
  };
}
