import { useCallback, useMemo, useState } from 'react';
import { PracticeSelector } from '../PracticeSelector/PracticeSelector';
import styles from './SignUpFormMasterPracticeSearch.module.css';
import { PageTitle } from '../PageTitle';
import {
  Text,
  Button,
  ActivityIndicator,
  Toast,
  ToastProps,
  ToastSentiment,
} from '@farmersdog/corgi-x';
import {
  PersonalInformationFormValues,
  SignUpFormWithPractice,
  conferenceModePersonalInformationFieldsData,
  generatePersonalInformationValidationSchema,
  personalInformationInitialFormValues,
  standardPersonalInformationFieldsData,
} from './SignUpFormWithPractice';
import {
  SignUpFormWithNoSelectedPractice,
  practiceOrOrganizationValidationSchema,
  signUpWithNoSelectedPracticeFieldsData,
  veterinaryPracticeOrOrganizationInformationFormValues,
} from './SignUpFormWithNoSelectedPractice';
import { useFormik } from 'formik';
import {
  CreateVetWithOrganizationMutation,
  FoundPractice,
  OrganizationDataSource,
  OrganizationInput,
  Position,
  ValidatePracticeQuery,
} from '../../graphql/types';
import { useValidatePractice } from '../../graphql/hooks/useValidatePractice';
import classNames from 'classnames';
import { useHistory, useLocation } from 'react-router';
import * as pagePaths from '../../constants/pagePaths';
import {
  useQueryParameters,
  useScrollRestore,
  useRedirection,
  useSignupSuccess,
  useDeepLink,
} from '../../hooks';
import { useCreateVetWithOrganization } from '../../graphql/hooks/useCreateVetWithOrganization';
import { useAuthContext } from '../../context';
import { getCurrentEmailSession } from '../../services/auth';
import { v4 } from 'uuid';
import { trackSignup } from '../../analytics/events';
import {
  defaultErrorMessage,
  getErrorMessage,
} from '../../services/auth/utils/errors';
import omit from 'lodash/omit';
import { useRestoreScrollOnModeChange } from '../../hooks/useRestoreScrollOnModeChange';
import { countryManualPractice, OrganizationType } from '../../constants';
import { getMasterPracticeUrl } from '../../utils';
import { Logger } from '@farmersdog/logger';
import { FetchResult } from '@apollo/client';
import { handleCreateVetFailure } from '../SignupForm/utils';
import { useVetDetailsLazy } from '../../graphql/hooks';

interface SignUpFormMasterPracticeSearchProps {
  isConferenceModeOn?: boolean;
}

const toastErrorTitle =
  'We were unable to retrieve your practice. Please try again.';
const pageTitle = 'Vet Team Portal Registration';
const pageSubtitle =
  'Get access to useful info on canine nutrition, downloadable resources for your practice, and discounts on fresh food.';

enum PracticeSearchStep {
  Search = 'search',
  Selected = 'selected',
  New = 'new',
  Add = 'add',
}

interface CreateVetWithOrganizationArgs {
  personalInformation: PersonalInformationFormValues;
  organization: OrganizationInput;
  password: string;
}

export function SignUpFormMasterPracticeSearch({
  isConferenceModeOn = false,
}: SignUpFormMasterPracticeSearchProps) {
  const { vetDetailsQuery } = useVetDetailsLazy({ fetchPolicy: 'no-cache' });
  const location = useLocation();
  const history = useHistory();
  const query = useQueryParameters();
  const { redirectTo } = useDeepLink();
  const push = useRedirection();
  const { restoreScroll } = useScrollRestore();
  useRestoreScrollOnModeChange();

  const {
    login,
    signup,
    assertAuth,
    logout,
    deleteUser,
    isAuthenticated,
    isLoading: isUseAuthContextLoading,
  } = useAuthContext();
  useSignupSuccess({ isAuthenticated, isConferenceModeOn, logout });

  const [step, setStep] = useState<PracticeSearchStep>(
    PracticeSearchStep.Search
  );
  const [selectedPractice, setSelectedPractice] = useState<FoundPractice>();
  const [searchTerm, setSearchTerm] = useState('');
  const [validatedPractice, setValidatedPractice] =
    useState<ValidatePracticeQuery['validatePractice']>();
  const [toast, setToast] = useState<ToastProps>({
    message: '',
    sentiment: ToastSentiment.Neutral,
    isOpen: false,
  });
  const [searchResults, setSearchResults] = useState<FoundPractice[]>([]);

  const {
    createVetWithOrganizationMutation,
    loading: creatingVetWithOrganizationLoading,
  } = useCreateVetWithOrganization();
  const { validatePractice, loading: validatePracticeLoading } =
    useValidatePractice();

  const urlPractice = useMemo(() => {
    const params = new URLSearchParams(location.search);
    return new URLSearchParams(params).get('practice');
  }, [location.search]);

  const source =
    urlPractice === 'search'
      ? OrganizationDataSource.Google
      : OrganizationDataSource.User;

  const type =
    urlPractice !== 'none' ? OrganizationType.Practice : OrganizationType.Other;

  const moveStep = useCallback(
    (newStep: PracticeSearchStep) => {
      if (newStep === PracticeSearchStep.Search) {
        const url = getMasterPracticeUrl({
          pathname: history.location.pathname,
          search: history.location.search,
          signupMode: 'search',
        });
        history.push(url);
      }
      setStep(newStep);
      restoreScroll();
    },
    [history, restoreScroll]
  );
  const onSelectedPracticeChange = useCallback(
    async (practice: FoundPractice, searchString: string) => {
      setSelectedPractice(practice);
      setSearchTerm(searchString);
      restoreScroll();
      try {
        const { data } = await validatePractice({
          variables: {
            practice: {
              address: practice.formattedAddress,
              name: practice.name,
            },
          },
        });
        if (!data?.validatePractice) {
          setToast({
            message: toastErrorTitle,
            sentiment: ToastSentiment.Negative,
            isOpen: true,
          });
          return;
        }

        setValidatedPractice(data.validatePractice);
        setStep(PracticeSearchStep.Selected);
      } catch {
        setToast({
          message: toastErrorTitle,
          sentiment: ToastSentiment.Negative,
          isOpen: true,
        });
      }
    },
    [restoreScroll, validatePractice]
  );

  const loading =
    validatePracticeLoading ||
    creatingVetWithOrganizationLoading ||
    isUseAuthContextLoading;

  const initialValues = personalInformationInitialFormValues;
  const initialValuesForNoPracticeSelected =
    veterinaryPracticeOrOrganizationInformationFormValues;

  const signupFormFieldsData = isConferenceModeOn
    ? conferenceModePersonalInformationFieldsData
    : standardPersonalInformationFieldsData;

  const signupFormNewPracticeFieldsData =
    signUpWithNoSelectedPracticeFieldsData({ isPractice: true });

  const signupFormNoPracticeFieldsData = signUpWithNoSelectedPracticeFieldsData(
    { isPractice: false }
  );

  const personalInformationForm = useFormik({
    initialValues: initialValues,
    validationSchema: generatePersonalInformationValidationSchema({
      isConferenceModeOn,
    }),
    // submit is used when submitting the form for search mode
    onSubmit: async personalInformationFormValues => {
      if (validatedPractice?.addressComponents) {
        const organization = {
          source,
          type,
          ...omit(validatedPractice.addressComponents, '__typename'),
          id: validatedPractice.id,
        } satisfies OrganizationInput;

        const { password: passwordField, ...personalInformation } =
          personalInformationFormValues;

        const password = isConferenceModeOn ? v4() : (passwordField as string);

        await createVetWithOrganization({
          personalInformation,
          organization,
          password,
        });
      }
    },
  });

  const practiceOrOrganizationInformationForm = useFormik({
    initialValues: initialValuesForNoPracticeSelected,
    validationSchema: practiceOrOrganizationValidationSchema,
    // submit is used when submitting the form for add and none modes
    onSubmit: async additionalInformation => {
      const personalInformationFormValues = personalInformationForm.values;
      const organization = {
        ...additionalInformation,
        source,
        type,
        country: countryManualPractice,
        state: additionalInformation.state!,
      } satisfies OrganizationInput;
      const { password: passwordField, ...personalInformation } =
        personalInformationFormValues;

      const password = isConferenceModeOn ? v4() : (passwordField as string);

      await createVetWithOrganization({
        personalInformation,
        organization,
        password,
      });
    },
  });

  const resetForms = () => {
    practiceOrOrganizationInformationForm.resetForm();
    personalInformationForm.resetForm();
    setSearchTerm('');
    setSearchResults([]);
    setSelectedPractice(undefined);
    setValidatedPractice(undefined);
    setStep(PracticeSearchStep.Search);
  };

  const createVetWithOrganization = async ({
    personalInformation,
    organization,
    password,
  }: CreateVetWithOrganizationArgs) => {
    if (!signup) {
      throw new Error('No signup function provided');
    }
    if (!login) {
      throw new Error('No login function provided');
    }
    if (!logout) {
      throw new Error('No logout function provided');
    }
    if (!assertAuth) {
      throw new Error('No assertAuth function provided');
    }

    try {
      const { email } = personalInformation;
      const currentEmailSession = await getCurrentEmailSession();
      const originParam = query.get('origin');

      if (!currentEmailSession) {
        await signup({ email: email, password });
        await login({ email: email, password });
      }

      const vetInput = {
        ...omit(personalInformation, 'password'),
        position: personalInformation.position as Position,
        needsPassword: isConferenceModeOn,
      };

      let data: FetchResult<CreateVetWithOrganizationMutation>['data'];

      try {
        const result = await createVetWithOrganizationMutation({
          variables: {
            vetInput,
            organizationInput: organization,
          },
        });
        data = result.data;
      } catch (error) {
        // if this also fails, the error will be caught by the outer catch block and log to our monitoring tool
        await handleCreateVetFailure({ deleteUser, vetDetailsQuery });
        throw error;
      }

      if (isConferenceModeOn) {
        await logout();
      }

      if (!data?.createVetWithOrganization) {
        setToast({
          message: defaultErrorMessage,
          sentiment: ToastSentiment.Negative,
          isOpen: true,
        });
        return;
      }

      trackSignup({
        email: email,
        origin: originParam ?? '',
      });
    } catch (e) {
      const message = getErrorMessage(e);
      setToast({
        message,
        sentiment: ToastSentiment.Negative,
        isOpen: true,
      });

      // default error message indicates an unexpected error
      if (message === defaultErrorMessage) {
        const logger = new Logger('auth:signup');
        logger.error('Unexpected error occurred', { error: e });
      }
      return;
    }
    if (isConferenceModeOn) {
      setToast({
        message: "You've successfully registered.",
        sentiment: ToastSentiment.Positive,
        isOpen: true,
      });
      resetForms();
      history.replace(
        getMasterPracticeUrl({
          pathname: history.location.pathname,
          search: history.location.search,
          signupMode: 'search',
        })
      );

      restoreScroll();
    } else {
      // push registered user to logged in account
      void assertAuth();

      push({
        noRedirectPathname: pagePaths.PATH_DASHBOARD,
      });
    }
  };

  const loginSection = useMemo(() => {
    if (loading) return null;
    if (
      !(
        urlPractice == PracticeSearchStep.Search &&
        step == PracticeSearchStep.Search
      )
    ) {
      return (
        <Button
          variant="primary"
          type="submit"
          form="master-search-form"
          className={styles.registerButton}
          disabled={
            (!(
              PracticeSearchStep.Selected == step &&
              personalInformationForm.dirty &&
              personalInformationForm.isValid
            ) &&
              !(
                PracticeSearchStep.Selected != step &&
                practiceOrOrganizationInformationForm.dirty &&
                practiceOrOrganizationInformationForm.isValid &&
                personalInformationForm.dirty &&
                personalInformationForm.isValid
              )) ||
            loading
          }
        >
          Register
        </Button>
      );
    }
    return (
      <>
        <Text className={styles.loginText} as="h3" variant="heading-16" bold>
          Already Have An Account?
        </Text>
        <Button
          variant="secondary"
          className={styles.loginButton}
          type="link"
          to={{
            pathname: pagePaths.PATH_LOGIN,
            search: redirectTo,
          }}
        >
          Log In
        </Button>
      </>
    );
  }, [
    loading,
    personalInformationForm.dirty,
    personalInformationForm.isValid,
    practiceOrOrganizationInformationForm.dirty,
    practiceOrOrganizationInformationForm.isValid,
    redirectTo,
    step,
    urlPractice,
  ]);

  const body = useMemo(() => {
    if (loading) {
      return (
        <div className={styles.loading}>
          <ActivityIndicator mode="dark" />
        </div>
      );
    }
    if (urlPractice === 'add') {
      return (
        <SignUpFormWithNoSelectedPractice
          isNewPractice
          fieldDataPracticeOrOrganizationInformation={
            signupFormNewPracticeFieldsData
          }
          practiceOrOrganizationInformationForm={
            practiceOrOrganizationInformationForm
          }
          fieldDataPersonalInformation={signupFormFieldsData}
          personalInformationForm={personalInformationForm}
        />
      );
    }
    if (urlPractice === 'none') {
      return (
        <SignUpFormWithNoSelectedPractice
          fieldDataPracticeOrOrganizationInformation={
            signupFormNoPracticeFieldsData
          }
          practiceOrOrganizationInformationForm={
            practiceOrOrganizationInformationForm
          }
          fieldDataPersonalInformation={signupFormFieldsData}
          personalInformationForm={personalInformationForm}
        />
      );
    }
    if (step === PracticeSearchStep.Search)
      return (
        <PracticeSelector
          selectedPractice={selectedPractice}
          onPracticeSelected={onSelectedPracticeChange}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          setSearchResults={setSearchResults}
          searchResults={searchResults}
          withNotAssociated
        />
      );

    if (step === PracticeSearchStep.Selected && validatedPractice) {
      return (
        <SignUpFormWithPractice
          validatedPractice={validatedPractice}
          onGoBack={() => moveStep(PracticeSearchStep.Search)}
          fieldData={signupFormFieldsData}
          form={personalInformationForm}
        />
      );
    }

    return null;
  }, [
    loading,
    moveStep,
    onSelectedPracticeChange,
    personalInformationForm,
    practiceOrOrganizationInformationForm,
    searchTerm,
    selectedPractice,
    signupFormFieldsData,
    signupFormNewPracticeFieldsData,
    signupFormNoPracticeFieldsData,
    step,
    urlPractice,
    validatedPractice,
    searchResults,
  ]);

  return (
    <>
      <Toast {...toast} autoClose onClose={() => setToast({ isOpen: false })} />

      <div className={styles.container}>
        <div className={styles.titleContainer}>
          <PageTitle title={pageTitle} subtitle={pageSubtitle} />
        </div>
        <div
          className={classNames(loading ? '' : styles.masterPracticeContainer)}
        >
          {body}
        </div>
        {loginSection}
      </div>
    </>
  );
}
