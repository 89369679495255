import * as yup from 'yup';
import { EMAIL_REGEXP } from '../../../../constants/validation';
import { howDidYouHearAboutUsOptions } from '../../../../constants';
import { PersonalInformationFormFields } from '../constants';
import { Position } from '../../../../graphql/types';
import { noTrailingWhitespace } from '../../../../utils/validation/no-trailing-whitespace';
import { fieldRequiredErrorMessage } from '../../../../utils';

const termsAndConditionsErrorMessage =
  'You must accept the terms and conditions.';

interface GeneratePersonalInformationValidationSchemaArgs {
  isConferenceModeOn: boolean;
}

export function generatePersonalInformationValidationSchema({
  isConferenceModeOn,
}: GeneratePersonalInformationValidationSchemaArgs) {
  return yup.object({
    [PersonalInformationFormFields.firstName]: yup
      .string()
      .required(fieldRequiredErrorMessage),
    [PersonalInformationFormFields.lastName]: yup
      .string()
      .required(fieldRequiredErrorMessage),

    [PersonalInformationFormFields.email]: yup
      .string()
      .required(fieldRequiredErrorMessage)
      .test('trailing whitespace', 'Remove spaces', noTrailingWhitespace)
      .matches(EMAIL_REGEXP, 'Invalid email address'),

    [PersonalInformationFormFields.howDidYouHearAboutUs]: isConferenceModeOn
      ? yup.string().oneOf(howDidYouHearAboutUsOptions)
      : yup
          .string()
          .required(fieldRequiredErrorMessage)
          .oneOf(howDidYouHearAboutUsOptions),
    [PersonalInformationFormFields.password]: isConferenceModeOn
      ? yup.string()
      : yup.string().min(6).required(fieldRequiredErrorMessage),
    [PersonalInformationFormFields.position]: yup
      .string()
      .required(fieldRequiredErrorMessage)
      .oneOf(Object.values(Position)),

    [PersonalInformationFormFields.acceptedPrivacyPolicy]: yup
      .boolean()
      .required(fieldRequiredErrorMessage)
      .oneOf([true], termsAndConditionsErrorMessage),
    [PersonalInformationFormFields.attestedAtSignup]: yup
      .boolean()
      .required(fieldRequiredErrorMessage)
      .oneOf([true], termsAndConditionsErrorMessage),
  });
}
