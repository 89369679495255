import { FC, useMemo } from 'react';
import {
  PublicPage,
  SignUpBanner,
  SignupForm,
  SignUpFormMasterPracticeSearch,
} from '../../components';

import { useSignupPracticeSearchMode } from '../../hooks';
import { usePublicPageFeatures } from '../../abTesting/PublicPageFeatures';

export const Signup: FC = () => {
  const { showSignUpBanner, showImprovedSignUpPage } = usePublicPageFeatures();
  const body = useMemo(() => {
    if (showImprovedSignUpPage) {
      return <SignupForm />;
    }
    return <SignUpFormMasterPracticeSearch />;
  }, [showImprovedSignUpPage]);
  useSignupPracticeSearchMode();

  return (
    <>
      <PublicPage>
        {showSignUpBanner && <SignUpBanner />}
        {body}
      </PublicPage>
    </>
  );
};
