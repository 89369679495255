import { Fragment, ReactNode } from 'react';
import {
  useImprovedSignUp,
  useSignUpBanner,
  useTermsChangesBanner,
  useVetPortalBanner,
} from './featureHooks';
import { PublicPageFeaturesContext } from './PublicPageFeaturesContext';

interface PublicPageFeaturesProvider {
  children: ReactNode;
}

export function PublicPageFeaturesProvider({
  children,
}: PublicPageFeaturesProvider) {
  const { showPortalBanner, bannerConfig } = useVetPortalBanner();
  const { showSignUpBanner } = useSignUpBanner();
  const { showImprovedSignUpPage } = useImprovedSignUp();
  const { showTermsChangesBanner } = useTermsChangesBanner();

  return (
    // Use a key to force a re-render of children when the  value changes
    <Fragment key={String(`${showPortalBanner}${showTermsChangesBanner}`)}>
      <PublicPageFeaturesContext.Provider
        value={{
          showPortalBanner,
          bannerConfig,
          showSignUpBanner,
          showImprovedSignUpPage,
          showTermsChangesBanner,
        }}
      >
        {children}
      </PublicPageFeaturesContext.Provider>
    </Fragment>
  );
}
