import { Grid, GridItem, Text } from '@farmersdog/corgi-x';
import { Card } from '../../components';
import { AccountPage } from '../../components/AccountPage';
import { ContactUsForm } from '../../components/ContactUsFormDeprecated';

import styles from './ContactUs.module.css';
import { ResponsiveProps } from '../../constants';

export const DeprecatedContactUs = ({ isMobile }: ResponsiveProps) => {
  return (
    <AccountPage>
      <Grid>
        <GridItem>
          <Text
            as="h2"
            variant={isMobile ? 'heading-28' : 'heading-40'}
            bold
            color="blueberry-3"
            topSpacing="none"
            bottomSpacing="none"
          >
            Contact Us
          </Text>
          <Text
            as="p"
            variant="article-16"
            color="charcoal-3"
            topSpacing="sm"
            bottomSpacing="none"
          >
            Fill out the form below and we’ll get back to you shortly!
          </Text>
        </GridItem>
        <Grid className={styles.container}>
          <GridItem>
            <Card>
              <ContactUsForm />
            </Card>
          </GridItem>
        </Grid>
      </Grid>
    </AccountPage>
  );
};
