import { ReactNode } from 'react';

import { PublicHeader, PublicHeaderProps } from '../PublicHeader';
import { FullFooter } from '../FullFooter';

interface PageProps extends PublicHeaderProps {
  children: ReactNode;
}

export function PublicPage({ children, showCTA }: PageProps) {
  return (
    <>
      <PublicHeader showCTA={showCTA} />
      {children}
      <FullFooter />
    </>
  );
}
