import { Divider, Grid, Text } from '@farmersdog/corgi-x';
import { Card } from '../../../components';
import { VetEvent } from '../../../data/eventsAndRecordingsData';
import { Event } from './Event';
import classNames from 'classnames';
import styles from './Events.module.css';
import { useVetPortalLunchAndLearn } from '../../../abTesting';
export enum EventType {
  UpcomingEvents = 'Upcoming Events',
  PastEvents = 'Past Events',
}
interface EventsProps {
  isMobile?: boolean;
  eventType: EventType;
  events: VetEvent[];
}

export const Events = ({ eventType, events, isMobile }: EventsProps) => {
  const { showVetPortalLunchAndLearn } = useVetPortalLunchAndLearn();
  const gridClassnames = classNames({
    [styles.upcomingEvents]:
      eventType === EventType.UpcomingEvents && showVetPortalLunchAndLearn,
  });

  const getshowLeftBorder = (index: number) =>
    !isMobile &&
    index === events.length - 1 &&
    eventType === EventType.UpcomingEvents &&
    showVetPortalLunchAndLearn;

  return (
    <Card>
      <Text
        as="h3"
        variant={isMobile ? 'heading-22' : 'heading-28'}
        bold
        color="blueberry-3"
        topSpacing="none"
        bottomSpacing="none"
      >
        {eventType}
      </Text>
      <Grid className={gridClassnames}>
        {events.map((event, index) => (
          <Grid
            key={`${event.type}-${event.name}-${event.hourLocation}`}
            flexDirection="column"
          >
            <Divider width={1} color="Kale1" spacing={isMobile ? 24 : 36} />
            <Event vetEvent={event} leftBorder={getshowLeftBorder(index)} />
          </Grid>
        ))}
      </Grid>
    </Card>
  );
};
