import { FC } from 'react';
import { SignupForm } from '../../components';

import { useSignupPracticeSearchMode } from '../../hooks';

export const SignupConference: FC = () => {
  useSignupPracticeSearchMode();

  return <SignupForm isConferenceModeOn />;
};
