import { Button, Divider, Grid, GridItem, Text } from '@farmersdog/corgi-x';
import { TrialDiscountsContainer } from '../TrialDiscountsContainer';
import {
  EligibleAccordion,
  IsCompleteVerificationCta,
  Step,
} from './components';

import { HowToGetDiscountSteps } from '../../constants';

import config from '../../../../config';

import styles from './HowToGetDiscount.module.css';
import { useState } from 'react';
import { useScrollRestore } from '../../../../hooks';

export interface HowToGetDiscountProps {
  handleContinue?: () => void;
  isCompleteVerification: boolean;
  handleIsCompleteVerification: () => void;
}

export function HowToGetDiscount({
  handleContinue,
  isCompleteVerification,
  handleIsCompleteVerification,
}: HowToGetDiscountProps) {
  const [showSteps, setShowSteps] = useState<boolean>(false);
  const { restoreScroll } = useScrollRestore();

  const handleCompleteVerification = () => {
    handleIsCompleteVerification();
    restoreScroll();
  };
  return (
    <TrialDiscountsContainer>
      <Grid
        className={styles.container}
        flexDirection={showSteps ? 'column-reverse' : 'column'}
        columnGap="lg"
      >
        {(showSteps || !isCompleteVerification) && (
          <GridItem>
            <GridItem xs={12} justifyContent="center">
              <Text
                as="h3"
                className={styles.title}
                variant="heading-22"
                color="blueberry-3"
                vSpacing="none"
                bold
              >
                How To Get the 50% Discount
              </Text>
            </GridItem>
            <div className={styles.stepsContainer}>
              <div className={styles.steps}>
                {HowToGetDiscountSteps.map(({ id, content }) => (
                  <Step key={id} enumerator={id} content={content} />
                ))}
              </div>
            </div>
            <GridItem className={styles.accordionContainer}>
              <EligibleAccordion />
            </GridItem>
            <GridItem>
              <Divider
                className={styles.ctaDivider}
                color="Charcoal0"
                spacing={36}
                width={1}
              />
            </GridItem>
            <GridItem className={styles.ctaContainer}>
              <GridItem justifyContent="center">
                <Button
                  href={`${config.get('app.website.url')}/signup?c=vet`}
                  type="anchor"
                  target="_blank"
                  className={styles.button}
                  variant={isCompleteVerification ? 'secondary' : 'primary'}
                  onClick={handleCompleteVerification}
                >
                  Subscribe and Get a Free Trial
                </Button>
              </GridItem>
            </GridItem>
          </GridItem>
        )}
        {isCompleteVerification && (
          <GridItem
            justifyContent="center"
            flexDirection="column"
            alignItems="center"
            className={styles.completeVerificationCard}
          >
            <IsCompleteVerificationCta>
              <Button className={styles.button} onClick={handleContinue}>
                Continue
              </Button>
            </IsCompleteVerificationCta>
            {!showSteps && (
              <Text
                as="span"
                variant="heading-16"
                onClick={() => setShowSteps(true)}
                className={styles.showSteps}
              >
                Review how to get the discount
              </Text>
            )}
          </GridItem>
        )}
      </Grid>
    </TrialDiscountsContainer>
  );
}
