import { isErrorWithName } from '../../../utils';

export const errorCodes = {
  USER_ALREADY_EXISTS: 'UsernameExistsException',
  INVALID_PASSWORD: 'InvalidPasswordException',
  USER_NOT_FOUND: 'UserNotFoundException',
  NOT_AUTHORIZED: 'NotAuthorizedException',
  EXPIRED_CODE: 'ExpiredCodeException',
} as const;

const errorMessages = new Map<string, string>([
  [errorCodes.USER_ALREADY_EXISTS, 'Looks like you’re already signed up.'],
  [
    errorCodes.INVALID_PASSWORD,
    'Password does not meet the minimum requirement of 6 characters.',
  ],
  [
    errorCodes.USER_NOT_FOUND,
    'Sorry, but the email or password you entered was incorrect.',
  ],
  [
    errorCodes.NOT_AUTHORIZED,
    'Sorry, but the email or password you entered was incorrect.',
  ],
  [
    errorCodes.EXPIRED_CODE,
    'Invalid code provided, please request a code again.',
  ],
]);

const errorMessagesResetPassword = new Map<string, string>([
  [errorCodes.USER_NOT_FOUND, 'The email you entered is invalid.'],
  [errorCodes.EXPIRED_CODE, 'The code you entered is invalid'],
]);

export const defaultErrorMessage = 'An unknown error has occurred';

export function getErrorMessage(
  error: unknown,
  isInResetPasswordScreen = false
) {
  if (isErrorWithName(error)) {
    if (isInResetPasswordScreen) {
      return errorMessagesResetPassword.get(error.name) ?? defaultErrorMessage;
    }
    return errorMessages.get(error.name) ?? defaultErrorMessage;
  }
  return defaultErrorMessage;
}
