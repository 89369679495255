import { FC, ReactElement, useMemo } from 'react';

import { useAuthContext } from '../../context';

import { Route, RouteProps, Redirect, useLocation } from 'react-router-dom';

import * as pagePaths from '../../constants/pagePaths';
import { AttestedAtSignupRoute } from './AttestedAtSignupRoute';

export interface AuthorizedRouteProps extends RouteProps {
  children: ReactElement;
}

export const AuthorizedRoute: FC<AuthorizedRouteProps> = ({
  children,
  path,
  exact,
}) => {
  const { isAuthenticated, isLoading } = useAuthContext();
  const { pathname, hash } = useLocation();

  const redirect = useMemo(() => {
    return `next=${pathname}${hash ?? ''}`;
  }, [pathname, hash]);

  return (
    <>
      {!isLoading && (
        <Route
          path={path}
          exact={exact}
          render={({ location }) =>
            isAuthenticated ? (
              <AttestedAtSignupRoute>{children}</AttestedAtSignupRoute>
            ) : (
              <Redirect
                to={{
                  pathname: pagePaths.PATH_SIGNUP,
                  search: redirect,
                  state: { from: location },
                }}
              />
            )
          }
        />
      )}
    </>
  );
};
