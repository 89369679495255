import { FC } from 'react';
import { PageTitle, PublicPage, ForgotPasswordForm } from '../../components';

import styles from './ForgotPassword.module.css';

export const ForgotPassword: FC = () => {
  return (
    <PublicPage>
      <section className={styles.container}>
        <div className={styles.titleContainer}>
          <PageTitle
            title={'Enter Email Address'}
            subtitle={
              'Trouble logging in? Enter your email to receive your code.'
            }
          />
        </div>
        <ForgotPasswordForm />
      </section>
    </PublicPage>
  );
};
