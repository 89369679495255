import { FC, useMemo } from 'react';

import { PageTitle, LoginForm, PublicPage } from '../../components';
import styles from './Login.module.css';

import * as pagePaths from '../../constants/pagePaths';
import { Button, Text } from '@farmersdog/corgi-x';
import { useQueryParameters } from '../../hooks';

export const Login: FC = () => {
  const query = useQueryParameters();
  const pageTitle = 'Welcome Back!';
  const pageSubtitle = 'We’re glad you’re here. Please log in to continue. ';

  const redirectTo = useMemo(() => {
    return query.toString();
  }, [query]);

  return (
    <PublicPage>
      <div className={styles.container}>
        <div className={styles.titleContainer}>
          <PageTitle title={pageTitle} subtitle={pageSubtitle} />
        </div>
        <LoginForm />
        <Text className={styles.registerText} as="h3" variant="heading-16" bold>
          Don’t have an account?
        </Text>
        <Button
          variant="secondary"
          type="link"
          className={styles.registerButton}
          to={{
            pathname: pagePaths.PATH_SIGNUP,
            search: redirectTo,
          }}
        >
          Register
        </Button>
      </div>
    </PublicPage>
  );
};
