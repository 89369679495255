import { PersonalInformationFormFields } from './signUpFormWithPracticeFieldsData';

export interface PersonalInformationFormValues {
  [PersonalInformationFormFields.firstName]: string;
  [PersonalInformationFormFields.lastName]: string;
  [PersonalInformationFormFields.position]: string | undefined;
  [PersonalInformationFormFields.email]: string;
  [PersonalInformationFormFields.acceptedPrivacyPolicy]: boolean;
  [PersonalInformationFormFields.attestedAtSignup]: boolean;
  [PersonalInformationFormFields.password]?: string;
  [PersonalInformationFormFields.howDidYouHearAboutUs]?: string;
}

export const personalInformationInitialFormValues: PersonalInformationFormValues =
  {
    [PersonalInformationFormFields.firstName]: '',
    [PersonalInformationFormFields.lastName]: '',
    [PersonalInformationFormFields.position]: undefined,
    [PersonalInformationFormFields.email]: '',
    [PersonalInformationFormFields.acceptedPrivacyPolicy]: false,
    [PersonalInformationFormFields.attestedAtSignup]: false,
    [PersonalInformationFormFields.password]: '',
    [PersonalInformationFormFields.howDidYouHearAboutUs]: undefined,
  };
