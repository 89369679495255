import styles from './ContactUs.module.css';
import { ResponsiveProps } from '../../constants';
import { DeprecatedContactUs } from './DeprecatedContactUs';

import { NewContactUs } from './NewContactUs';
import { useNewContactUsPage } from '../../abTesting/PublicPageFeatures/featureHooks';

export const ContactUsResponsive = ({ isMobile }: ResponsiveProps) => {
  const { showNewContactUsPage } = useNewContactUsPage();

  if (showNewContactUsPage) {
    return <NewContactUs isMobile={isMobile} />;
  }
  return <DeprecatedContactUs isMobile={isMobile} />;
};

export const ContactUs = () => {
  return (
    <>
      <div className={styles.mobile}>
        <ContactUsResponsive isMobile={true} />
      </div>
      <div className={styles.desktop}>
        <ContactUsResponsive isMobile={false} />
      </div>
    </>
  );
};
