import { Text, Link } from '@farmersdog/corgi-x';
import { navItems } from './nav-items';

import styles from './Nav.module.css';
import { trackLandingPageClickedHeroLink } from '../../../analytics/events';

export const Nav = () => {
  return (
    <nav className={styles.navContainer}>
      <ul className={styles.navList}>
        {navItems.map(item => (
          <li className={styles.navListItem} key={item.name}>
            <Link
              to={`#${item.hash}`}
              className={styles.link}
              onClick={() => {
                trackLandingPageClickedHeroLink({ title: item.name });
              }}
            >
              <Text bold color="white" variant="heading-16">
                {item.name}
              </Text>
            </Link>
          </li>
        ))}
      </ul>
    </nav>
  );
};
