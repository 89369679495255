import { useState, useCallback } from 'react';
import {
  ActivityIndicator,
  Button,
  Input,
  Text,
  Link,
} from '@farmersdog/corgi-x';

import { useDebounce } from '../../hooks';
import { FoundPractice } from 'src/graphql/types';
import { useFindPractice } from '../../graphql/hooks/useFindPractice';

import styles from './PracticeSelector.module.css';
import classNames from 'classnames';
import { getMasterPracticeUrl } from '../../utils';
import { useHistory } from 'react-router';

export interface PracticeSelectorProps {
  onPracticeSelected: (practice: FoundPractice, searchTerm: string) => void;
  selectedPractice?: FoundPractice;
  searchTerm: string;
  setSearchTerm: (arg: string) => void;
  searchResults: FoundPractice[];
  setSearchResults: (arg: FoundPractice[]) => void;
  withNotAssociated?: boolean;
  className?: string;
  onAddVetPracticeAction?: () => void;
  label?: string;
  placeholder?: string;
}
export const PRACTICE_SEARCH_DELAY = 1000;

export function PracticeSelector({
  onPracticeSelected,
  selectedPractice,
  searchTerm,
  setSearchTerm,
  searchResults,
  setSearchResults,
  withNotAssociated = false,
  className,
  onAddVetPracticeAction,
  label = 'Practice name and zip code (i.e. “Greenville Vets 12345”)',
  placeholder = 'Where do you work?',
}: PracticeSelectorProps) {
  const [loadMore, setLoadMore] = useState(false);
  const { findPractice, loading } = useFindPractice();
  const history = useHistory();
  const urlNewVetPractice = getMasterPracticeUrl({
    pathname: history.location.pathname,
    search: history.location.search,
    signupMode: 'add',
  });
  const urlNewOrganization = getMasterPracticeUrl({
    pathname: history.location.pathname,
    search: history.location.search,
    signupMode: 'none',
  });

  const updateSearchTerm = useCallback(
    async (value: string) => {
      setSearchTerm(value);
      setSearchResults([]);
      setLoadMore(false);
      if (value.length >= 3) {
        try {
          const { data: resultPractices } = await findPractice({
            variables: {
              searchTerm: value,
            },
          });

          if (
            !resultPractices?.findPractice ||
            resultPractices?.findPractice?.length === 0
          ) {
            setSearchResults([]);
            return;
          }

          setSearchResults(resultPractices.findPractice);
        } catch {
          setSearchResults([]);
        }
      } else {
        setSearchResults([]);
      }
    },
    [findPractice, setSearchResults, setSearchTerm]
  );

  const { changeValue } = useDebounce({
    delay: PRACTICE_SEARCH_DELAY,
    initialValue: searchTerm || '',
    callBackService: updateSearchTerm,
  });

  return (
    <section className={classNames([styles.container, className])}>
      <div className={styles.selectorContainer}>
        <Input
          type="text"
          width="80%"
          color="charcoal-3"
          label={placeholder}
          value={searchTerm}
          onChange={e => changeValue(e.target.value)}
          aria-controls="practiceInfo"
        />
        <Text as="span" variant="heading-16" color="charcoal-3">
          {label}
        </Text>
        <div aria-live="polite" id="practiceInfo" aria-label="search results">
          {searchResults.length > 0 && (
            <ul className={styles.practiceList}>
              {(loadMore ? searchResults : searchResults.slice(0, 10)).map(
                practiceItem => (
                  <li
                    key={practiceItem.id}
                    onClick={() => onPracticeSelected(practiceItem, searchTerm)}
                    className={classNames(
                      styles.practice,
                      practiceItem.id === selectedPractice?.id &&
                        styles.selected
                    )}
                    aria-label={practiceItem.name}
                  >
                    <div>
                      <Text
                        variant="heading-16"
                        color="charcoal-3"
                        className={styles.practiceTitle}
                      >
                        {practiceItem.name}
                      </Text>
                      <Text variant="heading-12" color="charcoal-2">
                        {practiceItem.formattedAddress}
                      </Text>
                    </div>
                  </li>
                )
              )}
            </ul>
          )}
          {!loadMore && searchResults.length > 10 && (
            <>
              <div
                className={styles.loadMore}
                onClick={() => setLoadMore(!loadMore)}
              >
                <Text variant="heading-16" color="carrot-2">
                  Load More
                </Text>
              </div>
            </>
          )}
          {!loading && searchResults.length === 0 && searchTerm.length > 0 && (
            <div className={styles.practiceList}>
              <div className={styles.center}>
                <Text variant="heading-16" color="charcoal-3">
                  No results
                </Text>
              </div>
            </div>
          )}
          {loading && (
            <div className={styles.practiceList} data-testid="loading">
              <div className={styles.center}>
                <ActivityIndicator mode="dark" />
              </div>
            </div>
          )}
          {searchTerm.length > 0 && !loading && (
            <div className={styles.practiceListFooter}>
              <Text as="h3" variant="heading-16" bold>
                Not seeing your practice?
              </Text>
              {onAddVetPracticeAction ? (
                <Button
                  variant="secondary"
                  className={styles.addVetPractice}
                  onClick={onAddVetPracticeAction}
                >
                  Add Vet Practice
                </Button>
              ) : (
                <Button
                  variant="secondary"
                  className={styles.addVetPractice}
                  to={urlNewVetPractice}
                  type="link"
                >
                  Add Vet Practice
                </Button>
              )}
            </div>
          )}
        </div>
      </div>
      {withNotAssociated && (
        <div className={styles.notAssociatedBlock}>
          <Link to={urlNewOrganization} className={styles.link}>
            <Text variant="heading-16" color="carrot-2">
              I’m not associated with a practice
            </Text>
          </Link>
        </div>
      )}
    </section>
  );
}
