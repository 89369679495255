import styles from './SignUpFormWithNoSelectedPractice.module.css';
import { FormikProps } from 'formik';
import { VeterinaryPracticeOrOrganizationInformationFormValues } from './constants';
import { FormFieldArray, FormFields } from '../../FormFields';
import { Link, Text } from '@farmersdog/corgi-x';
import {
  PersonalInformationFormFields,
  PersonalInformationFormValues,
} from '../SignUpFormWithPractice';
import classNames from 'classnames';
import { getAbsoluteLink, getMasterPracticeUrl } from '../../../utils';
import { CheckboxWithText } from '../../CheckboxWithText';
import { paths } from '@farmersdog/constants';
import { useHistory } from 'react-router';

export interface SignUpFormWithNoSelectedPracticeProps<
  T extends FormFieldArray,
  U extends FormFieldArray,
> {
  personalInformationForm: FormikProps<PersonalInformationFormValues>;
  practiceOrOrganizationInformationForm: FormikProps<VeterinaryPracticeOrOrganizationInformationFormValues>;
  fieldDataPersonalInformation: T;
  fieldDataPracticeOrOrganizationInformation: U;
  isNewPractice?: boolean;
}

export function SignUpFormWithNoSelectedPractice<
  T extends FormFieldArray,
  U extends FormFieldArray,
>({
  personalInformationForm,
  practiceOrOrganizationInformationForm,
  fieldDataPersonalInformation,
  fieldDataPracticeOrOrganizationInformation,
  isNewPractice = false,
}: SignUpFormWithNoSelectedPracticeProps<T, U>) {
  const history = useHistory();
  const url = getMasterPracticeUrl({
    pathname: history.location.pathname,
    search: history.location.search,
    signupMode: 'search',
  });

  return (
    <div>
      <form
        id="master-search-form"
        className={styles.formContainer}
        onSubmit={e => {
          e.preventDefault();
          practiceOrOrganizationInformationForm.handleSubmit(e);
        }}
      >
        <div className={styles.title}>
          <Text as="h3" variant="heading-22" bold>
            {isNewPractice ? 'Veterinary Practice' : 'Organization'}
          </Text>

          <Link className={styles.backLink} to={url}>
            <Text as="span" variant="heading-16">
              Back to Search
            </Text>
          </Link>
        </div>

        <div
          className={classNames(styles.containerFields, styles.splitLastForms)}
        >
          <FormFields
            // @ts-expect-error https://github.com/jaredpalmer/formik/issues/2023
            formik={practiceOrOrganizationInformationForm}
            fieldData={fieldDataPracticeOrOrganizationInformation}
          />
        </div>
        <div className={classNames(styles.title, styles.personalDetails)}>
          <Text as="h3" variant="heading-22" bold>
            Personal Details
          </Text>
        </div>

        <div className={styles.containerFields}>
          <FormFields
            // @ts-expect-error https://github.com/jaredpalmer/formik/issues/2023
            formik={personalInformationForm}
            fieldData={fieldDataPersonalInformation}
          />
        </div>
        <CheckboxWithText
          maxWidth
          checked={
            personalInformationForm.values[
              PersonalInformationFormFields.acceptedPrivacyPolicy
            ]
          }
          onChange={e => {
            personalInformationForm.handleChange(e);
          }}
          onBlur={personalInformationForm.handleBlur}
          error={
            personalInformationForm.errors[
              PersonalInformationFormFields.acceptedPrivacyPolicy
            ]
          }
          isTouched={
            personalInformationForm.touched[
              PersonalInformationFormFields.acceptedPrivacyPolicy
            ]
          }
          fieldName={PersonalInformationFormFields.acceptedPrivacyPolicy}
          labelContent={
            <>
              By clicking here, I have read and agree to The Farmer’s Dog’s{' '}
              <Link
                href={getAbsoluteLink(paths.PATH_TERMS)}
                target={'_blank'}
                className={styles.termsOfUse}
              >
                Terms of Use
              </Link>
              .
            </>
          }
        />
        <div className={styles.checkbox}>
          <CheckboxWithText
            maxWidth
            checked={
              personalInformationForm.values[
                PersonalInformationFormFields.attestedAtSignup
              ]
            }
            onChange={e => {
              personalInformationForm.handleChange(e);
            }}
            onBlur={personalInformationForm.handleBlur}
            error={
              personalInformationForm.errors[
                PersonalInformationFormFields.attestedAtSignup
              ]
            }
            isTouched={
              personalInformationForm.touched[
                PersonalInformationFormFields.attestedAtSignup
              ]
            }
            fieldName={PersonalInformationFormFields.attestedAtSignup}
            labelContent={
              <>
                By clicking here, I attest that the information I have provided
                to gain access to this site, intended strictly for veterinary
                professionals, is true and accurate to the best of my knowledge.
                I understand that if this information is found to be untrue, the
                company reserves the right to change or deny access to the site
                and any benefits contained therein.
              </>
            }
          />
        </div>
      </form>
    </div>
  );
}
