import { FieldType, FormFieldArray } from '../FormFields';

import { arrayToOptions } from '../../utils';

export enum ContactUsFormFields {
  firstName = 'firstName',
  lastName = 'lastName',
  email = 'email',
  phone = 'phone',
  contactReason = 'contactReason',
  message = 'message',
}

export const contactReason = [
  'I would like to request education/lunch & learn for my staff',
  'I would like to partner with TFD',
  'I have a question about a specific recipe/ingredient',
  'I would like to speak with someone from the vet team',
  'General Question',
];

export const contactUsFormFieldsData: FormFieldArray<ContactUsFormFields> = [
  {
    name: ContactUsFormFields.firstName,
    type: FieldType.Text,
    label: 'First Name',
  },
  {
    name: ContactUsFormFields.lastName,
    type: FieldType.Text,
    label: 'Last Name',
  },
  {
    name: ContactUsFormFields.email,
    type: FieldType.Text,
    label: 'Contact Email',
    inputMode: 'email',
  },
  {
    name: ContactUsFormFields.phone,
    type: FieldType.Text,
    label: 'Contact Phone Number (Optional)',
    inputMode: 'tel',
  },
  {
    name: ContactUsFormFields.contactReason,
    type: FieldType.Select,
    label: 'Reason for contact',
    options: arrayToOptions(contactReason),
    className: 'inputFullWidth',
  },
  {
    name: ContactUsFormFields.message,
    type: FieldType.TextArea,
    label: 'Message',
    className: 'inputFullWidth',
  },
];
