import { Card } from '../../../components';
import { Divider, Grid, Text } from '@farmersdog/corgi-x';
import { Video } from './Video';
import { getEventsAndRecordingsData } from '../../../data/eventsAndRecordingsData';
import { useCreditCopy } from '../../../abTesting';
import { joinText } from '../../../utils/joinText';

interface OnDemandVideosProps {
  isMobile?: boolean;
}

export const OnDemandVideos = ({ isMobile }: OnDemandVideosProps) => {
  const { showCreditCopy } = useCreditCopy();

  function getSpeakersName(speakers: string[]): string {
    return joinText({
      elements: speakers,
      separator: ', ',
      endSeparator: ' & ',
    });
  }

  return (
    <Card>
      <Text
        as="h3"
        variant={isMobile ? 'heading-22' : 'heading-28'}
        bold
        color="blueberry-3"
        topSpacing={'none'}
        bottomSpacing="sm"
      >
        On Demand Videos
      </Text>
      <Text variant="article-16" color="charcoal-3">
        {showCreditCopy
          ? `You can earn RACE-approved continuing education credits on-demand by
        watching previous webinars.`
          : `CE and RACE credits were available for the live sessions. You will not
        receive continuing education credit hours for watching the recording.`}
      </Text>

      {getEventsAndRecordingsData({ showCreditCopy }).map(event => (
        <Grid key={event.videoId} bottomSpacing="md">
          <Divider width={1} color="Kale1" spacing={36} />
          <Video
            isMobile={isMobile}
            type={event.type}
            name={event.videoTitle}
            speaker={getSpeakersName(
              event.speakers.map(speaker => speaker.nameAndTitle)
            )}
            dateAndLocation={event.webinarDate}
            videoId={event.videoId}
            wistiaOptions={event.wistiaOptions}
          />
        </Grid>
      ))}
    </Card>
  );
};
