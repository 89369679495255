import {
  Button,
  Divider,
  Text,
  StyledSelect,
  StyledOption,
} from '@farmersdog/corgi-x';
import styles from './UpdateRole.module.css';
import { Position } from '../../../graphql/types';
import { positionOptions } from '../../../constants';
import { useState } from 'react';
export interface UpdateRoleResponsiveProps extends UpdateRoleProps {
  isMobile?: boolean;
}

const UpdateRoleResponsive = ({
  isMobile,
  onSubmit,
  disableSubmit,
  position,
}: UpdateRoleResponsiveProps) => {
  const [currentPosition, setCurrentPosition] = useState<Position>(position);
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onSubmit(currentPosition);
  };
  return (
    <div className={styles.container}>
      <Text
        color="blueberry-3"
        variant={isMobile ? 'heading-28' : 'heading-40'}
        bold
        as="h1"
      >
        Update Your Role
      </Text>
      <form onSubmit={handleSubmit}>
        <Divider width={1} color="Charcoal0" />
        <div className={styles.formContent}>
          <StyledSelect
            value={currentPosition}
            onChange={e => setCurrentPosition(e?.value as Position)}
            label="Role"
          >
            {positionOptions.map(positionOption => (
              <StyledOption
                key={positionOption.value}
                value={positionOption.value}
                label={positionOption.label}
              >
                {positionOption.label}
              </StyledOption>
            ))}
          </StyledSelect>
        </div>
        <Divider width={1} color="Charcoal0" />
        <Button
          type="submit"
          variant="primary"
          aria-disabled={position === currentPosition || disableSubmit}
        >
          Save Changes
        </Button>
      </form>
    </div>
  );
};

export interface UpdateRoleProps {
  position: Position;
  onSubmit: (position: Position) => void;
  disableSubmit: boolean;
}

export const UpdateRole = (props: UpdateRoleProps) => {
  return (
    <>
      <div className={styles.mobile}>
        <UpdateRoleResponsive isMobile {...props} />
      </div>
      <div className={styles.desktop}>
        <UpdateRoleResponsive {...props} />
      </div>
    </>
  );
};
