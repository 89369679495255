import { useCallback, useMemo, useState } from 'react';
import { PracticeSelector } from '../PracticeSelector/PracticeSelector';
import styles from './SignupForm.module.css';
import { PageTitle } from '../PageTitle';
import {
  Button,
  Toast,
  ToastProps,
  ToastSentiment,
  Link,
  ActivityIndicator,
  Logo,
  Text,
  Grid,
} from '@farmersdog/corgi-x';
import {
  conferencePersonalInformationFormFieldsData,
  PersonalInformationConferenceFormValues,
  personalInformationConferenceFormValues,
  PersonalInformationFormFields,
  PersonalInformationFormValues,
  personalInformationFormValues,
  standardPersonalInformationFieldsData,
} from './PersonalInformationForm/constants';
import {
  veterinaryPracticeOrOrganizationInformationFormValues,
  signUpWithNoSelectedPracticeFieldsData,
} from './SignUpFormWithNoSelectedPractice/constants';
import {
  personalInformationConferenceValidationSchema,
  personalInformationValidationSchema,
} from './PersonalInformationForm/utils';
import * as pagePaths from '../../constants/pagePaths';

import { useFormik } from 'formik';
import {
  CreateVetWithOrganizationMutation,
  FoundPractice,
  OrganizationDataSource,
  OrganizationInput,
  Position,
  ValidatePracticeQuery,
} from '../../graphql/types';
import { useValidatePractice } from '../../graphql/hooks/useValidatePractice';
import { getAbsoluteLink, getMasterPracticeUrl } from '../../utils';
import { useHistory, useLocation } from 'react-router';
import {
  useQueryParameters,
  useScrollRestore,
  useSignupSuccess,
  useRestoreScrollOnModeChange,
  useRedirection,
  useDeepLink,
} from '../../hooks';
import { useCreateVetWithOrganization } from '../../graphql/hooks/useCreateVetWithOrganization';
import { useAuthContext } from '../../context';
import { getCurrentEmailSession } from '../../services/auth';
import { v4 } from 'uuid';
import { trackSignup } from '../../analytics/events';
import {
  defaultErrorMessage,
  getErrorMessage,
} from '../../services/auth/utils/errors';
import omit from 'lodash/omit';
import { countryManualPractice, OrganizationType } from '../../constants';
import {
  SignUpFormWithNoSelectedPractice,
  practiceOrOrganizationValidationSchema,
} from './SignUpFormWithNoSelectedPractice';
import { PersonalInformationForm } from './PersonalInformationForm';
import { CheckboxWithText } from '../CheckboxWithText';
import { paths } from '@farmersdog/constants';
import { PracticeInformation } from '../PracticeInformation';
import WillEatAnythingSVG from './assets/WillEatAnything.svg?react';
import classNames from 'classnames';
import { usePublicPageFeatures } from '../../abTesting/PublicPageFeatures';
import { Logger } from '@farmersdog/logger';
import { FetchResult } from '@apollo/client';
import { handleCreateVetFailure } from './utils';
import { useVetDetailsLazy } from '../../graphql/hooks';

const toastErrorTitle =
  'We were unable to retrieve your practice. Please try again.';
const pageTitle = 'Vet Team Portal Registration';
const pageTitleConference = 'Vet Portal Registration';
const subtitle =
  'Register yourself to get access to useful info on canine nutrition, downloadable resources for your practice, and discounts on fresh food.';

enum PracticeSearchStep {
  Search = 'search',
  Selected = 'selected',
  New = 'new',
  Add = 'add',
}
export const DONE_SCREEN_TIMEOUT = 3000;

interface CreateVetWithOrganizationArgs {
  personalInformation:
    | PersonalInformationFormValues
    | PersonalInformationConferenceFormValues;
  organization: OrganizationInput;
  password: string;
}

interface GetPasswordArgs {
  personalInformationValues:
    | PersonalInformationFormValues
    | PersonalInformationConferenceFormValues;
  isConferenceModeOn: boolean;
}

const formsValuesContainPassword = (
  formValues:
    | PersonalInformationFormValues
    | PersonalInformationConferenceFormValues
): formValues is PersonalInformationFormValues => {
  return Object.prototype.hasOwnProperty.call(formValues, 'password');
};

export const getPassword = ({
  personalInformationValues,
  isConferenceModeOn,
}: GetPasswordArgs) => {
  let password = '';
  if (
    !isConferenceModeOn &&
    formsValuesContainPassword(personalInformationValues)
  ) {
    password = personalInformationValues.password;
  } else {
    password = v4();
  }
  return password;
};

interface SignupFormProps {
  isConferenceModeOn?: boolean;
}

export function SignupForm({ isConferenceModeOn = false }: SignupFormProps) {
  const { vetDetailsQuery } = useVetDetailsLazy({ fetchPolicy: 'no-cache' });
  const history = useHistory();
  const location = useLocation();
  const query = useQueryParameters();
  const push = useRedirection();
  const { showImprovedSignUpPage } = usePublicPageFeatures();

  const { restoreScroll } = useScrollRestore();
  useRestoreScrollOnModeChange();

  const { assertAuth, login, signup, logout, deleteUser, isAuthenticated } =
    useAuthContext();
  useSignupSuccess({ isAuthenticated, isConferenceModeOn, logout });

  const [step, setStep] = useState<PracticeSearchStep>(
    PracticeSearchStep.Search
  );
  const [selectedPractice, setSelectedPractice] = useState<FoundPractice>();
  const [searchTerm, setSearchTerm] = useState('');
  const [validatedPractice, setValidatedPractice] =
    useState<ValidatePracticeQuery['validatePractice']>();
  const [toast, setToast] = useState<ToastProps>({
    message: '',
    sentiment: ToastSentiment.Neutral,
    isOpen: false,
  });
  const [searchResults, setSearchResults] = useState<FoundPractice[]>([]);
  const [submitted, setSubmitted] = useState(false);
  const [submissionLoading, setSubmissionLoading] = useState(false);
  const { createVetWithOrganizationMutation } = useCreateVetWithOrganization();
  const { validatePractice, loading: validatePracticeLoading } =
    useValidatePractice();

  const urlPractice = useMemo(() => {
    const params = new URLSearchParams(location.search);
    return new URLSearchParams(params).get('practice');
  }, [location.search]);

  const source =
    urlPractice === 'search'
      ? OrganizationDataSource.Google
      : OrganizationDataSource.User;

  const type =
    urlPractice !== 'none' ? OrganizationType.Practice : OrganizationType.Other;

  const onSelectedPracticeChange = useCallback(
    async (practice: FoundPractice, searchString: string) => {
      setSelectedPractice(practice);
      setSearchTerm(searchString);
      try {
        const { data } = await validatePractice({
          variables: {
            practice: {
              address: practice.formattedAddress,
              name: practice.name,
            },
          },
        });
        if (!data?.validatePractice) {
          setToast({
            message: toastErrorTitle,
            sentiment: ToastSentiment.Negative,
            isOpen: true,
          });
          return;
        }

        setValidatedPractice(data.validatePractice);
        setStep(PracticeSearchStep.Selected);
      } catch {
        setToast({
          message: toastErrorTitle,
          sentiment: ToastSentiment.Negative,
          isOpen: true,
        });
      }
    },
    [validatePractice]
  );

  const personalInformationInitialValues = isConferenceModeOn
    ? personalInformationConferenceFormValues
    : personalInformationFormValues;
  const personalInformationConferenceFormFieldsData = isConferenceModeOn
    ? conferencePersonalInformationFormFieldsData
    : standardPersonalInformationFieldsData;
  const initialValuesForNoPracticeSelected =
    veterinaryPracticeOrOrganizationInformationFormValues;

  const signupFormNewPracticeFieldsData =
    signUpWithNoSelectedPracticeFieldsData({ isPractice: true });

  const signupFormNoPracticeFieldsData = signUpWithNoSelectedPracticeFieldsData(
    { isPractice: false }
  );

  const personalInformationSchemaForm = isConferenceModeOn
    ? personalInformationConferenceValidationSchema
    : personalInformationValidationSchema;

  const personalInformationForm = useFormik({
    initialValues: personalInformationInitialValues,
    validationSchema: personalInformationSchemaForm,
    // submit is used when submitting the form for search mode
    onSubmit: async personalInformationValues => {
      if (validatedPractice?.addressComponents) {
        const organization = {
          source,
          type,
          ...omit(validatedPractice.addressComponents, '__typename'),
          id: validatedPractice.id,
        } satisfies OrganizationInput;

        const password = getPassword({
          personalInformationValues,
          isConferenceModeOn,
        });

        await createVetWithOrganization({
          personalInformation: personalInformationValues,
          organization,
          password,
        });
      }
    },
  });

  const organizationOrPracticeForm = useFormik({
    initialValues: initialValuesForNoPracticeSelected,
    validationSchema: practiceOrOrganizationValidationSchema,
    // submit is used when submitting the form for add and none modes
    onSubmit: async additionalInformation => {
      const personalInformationValues = personalInformationForm.values;
      const organization = {
        ...additionalInformation,
        source,
        type,
        country: countryManualPractice,
        state: additionalInformation.state!,
      } satisfies OrganizationInput;

      const password = getPassword({
        personalInformationValues,
        isConferenceModeOn,
      });

      await createVetWithOrganization({
        personalInformation: personalInformationValues,
        organization,
        password,
      });
    },
  });

  const resetForms = () => {
    organizationOrPracticeForm.resetForm();
    personalInformationForm.resetForm();
    setSearchTerm('');
    setSearchResults([]);
    setSelectedPractice(undefined);
    setValidatedPractice(undefined);
    setStep(PracticeSearchStep.Search);
  };

  const createVetWithOrganization = async ({
    personalInformation,
    organization,
    password,
  }: CreateVetWithOrganizationArgs) => {
    if (!signup) {
      throw new Error('No signup function provided');
    }
    if (!login) {
      throw new Error('No login function provided');
    }
    if (!logout) {
      throw new Error('No logout function provided');
    }
    if (!isConferenceModeOn) {
      if (!assertAuth) {
        throw new Error('No assertAuth function provided');
      }
    }

    try {
      setSubmissionLoading(true);
      const { email } = personalInformation;
      const currentEmailSession = await getCurrentEmailSession();
      const originParam = query.get('origin');

      if (!currentEmailSession) {
        await signup({ email: email, password });
        await login({ email: email, password });
      }

      const vetInput = {
        ...omit(personalInformation, 'password'),
        position: personalInformation.position as Position,
        needsPassword: isConferenceModeOn,
      };

      let data: FetchResult<CreateVetWithOrganizationMutation>['data'];

      try {
        const result = await createVetWithOrganizationMutation({
          variables: {
            vetInput,
            organizationInput: organization,
          },
        });
        data = result.data;
      } catch (error) {
        // if this also fails, the error will be caught by the outer catch block and log to our monitoring tool
        await handleCreateVetFailure({
          deleteUser,
          vetDetailsQuery,
        });
        throw error;
      }

      if (isConferenceModeOn) {
        await logout();
      }

      if (!data?.createVetWithOrganization) {
        setSubmissionLoading(false);
        setToast({
          message: defaultErrorMessage,
          sentiment: ToastSentiment.Negative,
          isOpen: true,
        });
        return;
      }

      trackSignup({
        email: email,
        origin: originParam ?? '',
      });
    } catch (e) {
      setSubmissionLoading(false);
      const message = getErrorMessage(e);
      setToast({
        message,
        sentiment: ToastSentiment.Negative,
        isOpen: true,
      });
      // default error message indicates an unexpected error
      if (message === defaultErrorMessage) {
        const logger = new Logger('auth:signup');
        logger.error('Unexpected error occurred', { error: e });
      }
      return;
    }
    if (isConferenceModeOn) {
      resetForms();
      setSubmitted(true);
      history.replace(
        getMasterPracticeUrl({
          pathname: history.location.pathname,
          search: history.location.search,
          signupMode: 'search',
        })
      );
      restoreScroll();
      setSubmissionLoading(false);
      setTimeout(() => {
        setSubmitted(false);
      }, DONE_SCREEN_TIMEOUT);

      // clear error
      setToast({
        message: '',
        isOpen: false,
      });
    } else {
      // push registered user to logged in account
      if (assertAuth) {
        void assertAuth();
      }

      push({
        noRedirectPathname: pagePaths.PATH_DASHBOARD,
      });
    }
  };

  const body = useMemo(() => {
    if (urlPractice === 'add') {
      return (
        <SignUpFormWithNoSelectedPractice
          fieldData={signupFormNewPracticeFieldsData}
          form={organizationOrPracticeForm}
          isConferenceModeOn={isConferenceModeOn}
          title="Add Veterinary Practice"
        >
          <PersonalInformationForm
            fieldData={personalInformationConferenceFormFieldsData}
            form={personalInformationForm}
            isConferenceModeOn={isConferenceModeOn}
            withForm={false}
          />
        </SignUpFormWithNoSelectedPractice>
      );
    }
    if (urlPractice === 'none') {
      return (
        <SignUpFormWithNoSelectedPractice
          title="Add Organization"
          fieldData={signupFormNoPracticeFieldsData}
          form={organizationOrPracticeForm}
          isConferenceModeOn={isConferenceModeOn}
        >
          <PersonalInformationForm
            fieldData={personalInformationConferenceFormFieldsData}
            form={personalInformationForm}
            withForm={false}
            isConferenceModeOn={isConferenceModeOn}
          />
        </SignUpFormWithNoSelectedPractice>
      );
    }
    if (step === PracticeSearchStep.Search)
      return (
        <PersonalInformationForm
          isConferenceModeOn={isConferenceModeOn}
          fieldData={personalInformationConferenceFormFieldsData}
          form={personalInformationForm}
        >
          {validatePracticeLoading ? (
            <div className={styles.loadingPractice}>
              <ActivityIndicator mode="dark" />
            </div>
          ) : (
            <PracticeSelector
              selectedPractice={selectedPractice}
              onPracticeSelected={onSelectedPracticeChange}
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
              setSearchResults={setSearchResults}
              searchResults={searchResults}
              withNotAssociated
            />
          )}
        </PersonalInformationForm>
      );

    if (step === PracticeSearchStep.Selected && validatedPractice) {
      return (
        <PersonalInformationForm
          fieldData={personalInformationConferenceFormFieldsData}
          form={personalInformationForm}
          isConferenceModeOn={isConferenceModeOn}
        >
          <PracticeInformation
            name={validatedPractice?.addressComponents?.name}
            address={validatedPractice.formattedAddress ?? ''}
            onUpdate={() => {
              setStep(PracticeSearchStep.Search);
            }}
          />
        </PersonalInformationForm>
      );
    }

    return null;
  }, [
    urlPractice,
    step,
    personalInformationConferenceFormFieldsData,
    personalInformationForm,
    validatePracticeLoading,
    selectedPractice,
    onSelectedPracticeChange,
    searchTerm,
    searchResults,
    isConferenceModeOn,
    validatedPractice,
    signupFormNewPracticeFieldsData,
    organizationOrPracticeForm,
    signupFormNoPracticeFieldsData,
  ]);

  if (submissionLoading) {
    return (
      <div className={styles.loading}>
        {isConferenceModeOn && (
          <Logo variant="product" size={75} className={styles.logo} />
        )}
        <div className={styles.activityIndicator}>
          <ActivityIndicator mode="dark" />
        </div>
      </div>
    );
  }
  if (submitted) {
    return (
      <div className={styles.submitted}>
        {isConferenceModeOn && (
          <Logo variant="product" size={75} className={styles.logo} />
        )}

        <div className={styles.logoContainer}>
          <div className={styles.willEatAnything}>
            <WillEatAnythingSVG />
          </div>
          <Text as="h4" variant="heading-40" bold>
            Thanks for registering!
          </Text>
          <Button
            variant="secondary"
            onClick={() => {
              setSubmitted(false);
            }}
          >
            Done
          </Button>
        </div>
      </div>
    );
  }

  return (
    <>
      <Toast {...toast} autoClose onClose={() => setToast({ isOpen: false })} />
      <div className={styles.container}>
        {isConferenceModeOn && (
          <Logo variant="product" size={75} className={styles.logo} />
        )}
        <div
          className={classNames(styles.titleContainer, {
            [styles.addSpacing]: !isConferenceModeOn,
          })}
        >
          <PageTitle
            title={isConferenceModeOn ? pageTitleConference : pageTitle}
            subtitle={isConferenceModeOn ? '' : subtitle}
          />
        </div>

        <div className={styles.formsContainer}>
          {body}
          <div className={styles.termsAndConditions}>
            <CheckboxWithText
              maxWidth
              checked={
                personalInformationForm.values[
                  PersonalInformationFormFields.acceptedPrivacyPolicy
                ]
              }
              onChange={e => {
                personalInformationForm.handleChange(e);
              }}
              onBlur={personalInformationForm.handleBlur}
              error={
                personalInformationForm.errors[
                  PersonalInformationFormFields.acceptedPrivacyPolicy
                ]
              }
              isTouched={
                personalInformationForm.touched[
                  PersonalInformationFormFields.acceptedPrivacyPolicy
                ]
              }
              fieldName={PersonalInformationFormFields.acceptedPrivacyPolicy}
              labelContent={
                showImprovedSignUpPage ? (
                  <>I agree to the Terms of Use</>
                ) : (
                  <>
                    By clicking here, I have read and agree to The Farmer’s
                    Dog’s{' '}
                    <Link
                      href={getAbsoluteLink(paths.PATH_TERMS)}
                      target={'_blank'}
                      className={styles.termsOfUse}
                    >
                      Terms of Use
                    </Link>
                    .
                  </>
                )
              }
            />
            {showImprovedSignUpPage && (
              <Text
                variant="heading-12"
                topSpacing="sm"
                className={styles.subtitleLink}
              >
                I have read and agree to The Farmer’s Dog’s{' '}
                <Link
                  href={getAbsoluteLink(paths.PATH_TERMS)}
                  target={'_blank'}
                  className={styles.termsOfUse}
                >
                  Terms of Use
                </Link>
              </Text>
            )}

            <CheckboxWithText
              maxWidth
              checked={
                personalInformationForm.values[
                  PersonalInformationFormFields.attestedAtSignup
                ]
              }
              onChange={e => {
                personalInformationForm.handleChange(e);
              }}
              onBlur={personalInformationForm.handleBlur}
              error={
                personalInformationForm.errors[
                  PersonalInformationFormFields.attestedAtSignup
                ]
              }
              isTouched={
                personalInformationForm.touched[
                  PersonalInformationFormFields.attestedAtSignup
                ]
              }
              fieldName={PersonalInformationFormFields.attestedAtSignup}
              labelContent={
                showImprovedSignUpPage ? (
                  <>I attest to the accuracy of my information</>
                ) : (
                  <>
                    By clicking here, I attest that the information I have
                    provided to gain access to this site, intended strictly for
                    veterinary professionals, is true and accurate to the best
                    of my knowledge. I understand that if this information is
                    found to be untrue, the company reserves the right to change
                    or deny access to the site and any benefits contained
                    therein.
                  </>
                )
              }
            />
            {showImprovedSignUpPage && (
              <Text
                variant="heading-12"
                topSpacing="sm"
                className={styles.subtitleLink}
              >
                I attest that the information I have provided to gain access to
                this site, intended strictly for veterinary professionals, is
                true and accurate to the best of my knowledge. I understand that
                if this information is found to be untrue, the company reserves
                the right to change or deny access to the site and any benefits
                contained therein.
              </Text>
            )}
          </div>
        </div>
        <Button
          variant="primary"
          type="submit"
          form="master-search-form"
          className={styles.submitButton}
          disabled={
            (!(
              PracticeSearchStep.Selected == step &&
              personalInformationForm.dirty &&
              personalInformationForm.isValid
            ) &&
              !(
                PracticeSearchStep.Selected != step &&
                organizationOrPracticeForm.dirty &&
                organizationOrPracticeForm.isValid &&
                personalInformationForm.dirty &&
                personalInformationForm.isValid
              )) ||
            submissionLoading
          }
        >
          {showImprovedSignUpPage ? 'Register' : 'Submit'}
        </Button>
      </div>
      {!isConferenceModeOn && <Login />}
    </>
  );
}

const Login = () => {
  const { redirectTo } = useDeepLink();

  return (
    <Grid flexDirection="column" className={styles.login} gap="md">
      <Text bold align="center">
        Already have an account?
      </Text>
      <Text align="center">
        <Button
          to={{
            pathname: pagePaths.PATH_LOGIN,
            search: redirectTo,
          }}
          type="link"
          variant="secondary"
        >
          Log In
        </Button>
      </Text>
    </Grid>
  );
};
