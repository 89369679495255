import { Button, Divider, Text } from '@farmersdog/corgi-x';
import styles from './ContactUsForm.module.css';
import * as externalLinks from '../../constants/externalLinks';
import { contactReasons } from '../../pages/ContactUs/NewContactUs';
import {
  trackContactUsClickedLunchAndLearnRequest,
  trackContactUsClickedUniversityVisitRequest,
} from '../../analytics/events';

interface ExternalFormLinkProps {
  reasonSelection: string;
}

export const ExternalFormLink = ({
  reasonSelection,
}: ExternalFormLinkProps) => {
  const handleButtonClick = () => {
    if (reasonSelection === contactReasons.lunchAndLearn) {
      trackContactUsClickedLunchAndLearnRequest();
    }

    if (reasonSelection === contactReasons.universityVisit) {
      trackContactUsClickedUniversityVisitRequest();
    }
  };

  return (
    <div className={styles.formContainer}>
      <Divider
        orientation="horizontal"
        color="Charcoal0"
        width={1}
        spacing={12}
      />
      <div className={styles.linkContainer}>
        <Text color="Carrot3" spacing={'sm'}>
          Complete our request form to get you scheduled!
        </Text>
        <Button
          onClick={handleButtonClick}
          variant="primary"
          className={styles.submitButton}
          type="anchor"
          target="_blank"
          href={externalLinks.PATH_LUNCH_AND_LEARN_REQUEST}
        >
          Continue to Request Form
        </Button>
      </div>
    </div>
  );
};
