import { Button, Section, Text } from '@farmersdog/corgi-x';
import { useId } from 'react';
interface UpdatePasswordProps {
  disableUpdatePassword?: boolean;
  onUpdatePassword: () => void;
}

export const UpdatePassword = ({
  disableUpdatePassword,
  onUpdatePassword,
}: UpdatePasswordProps) => {
  const headingId = useId();
  return (
    <Section aria-labelledby={headingId} variant="card" as="div">
      <Text
        id={headingId}
        as="h2"
        variant="heading-28"
        bold
        color="blueberry-3"
      >
        Password
      </Text>
      <Button
        variant="plain-text"
        underline
        weight="normal"
        onClick={!disableUpdatePassword ? () => onUpdatePassword() : undefined}
        aria-disabled={disableUpdatePassword}
      >
        Update Password
      </Button>
    </Section>
  );
};
