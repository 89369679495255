import { PATH_EVENT_AND_RECORDING } from '../constants';
import { Text, type NativeWistiaOptions } from '@farmersdog/corgi-x';
import donnaWebp from './assets/donna.webp';
import donnaJp2 from './assets/donna.jp2';
import donnaPng from './assets/donna.png';
import lauraWebp from './assets/laura.webp';
import lauraJp2 from './assets/laura.jp2';
import lauraPng from './assets/laura.png';
import raeWebp from '../pages/OurTeam/assets/rae.webp';
import raeJp2 from '../pages/OurTeam/assets/rae.jp2';
import raePng from '../pages/OurTeam/assets/rae.png';
import brandonWebp from '../pages/OurTeam/assets/brandon.webp';
import brandonJp2 from '../pages/OurTeam/assets/brandon.jp2';
import brandonPng from '../pages/OurTeam/assets/brandon.png';

import config from '../config';
import { FunctionComponent, SVGProps } from 'react';
import University from './assets/University.svg?react';
import LunchAndLearn from './assets/LunchAndLearn.svg?react';
import * as externalLinks from '../constants';
import { trackEventsAndRecordingsPageClickedLunchAndLearnRequest } from '../analytics/events/trackEventsAndRecordingsPageClickedLunchAndLearnRequest';
import { trackEventsAndRecordingsPageClickedUniversityVisitRequest } from '../analytics/events/trackEventsAndRecordingsPageClickedUniversityVisitRequest';

const donnaProfileSources = [donnaWebp, donnaJp2, donnaPng];
const lauraProfileSources = [lauraWebp, lauraJp2, lauraPng];
const raeProfileSources = [raeWebp, raeJp2, raePng];
const brandonProfileSources = [brandonWebp, brandonJp2, brandonPng];

const defaultWistiaOptions: NativeWistiaOptions = {
  autoPlay: false,
  controlsVisibleOnLoad: true,
  fitStrategy: 'cover',
  popover: true,
  playsinline: true,
  playbar: true,
  playButton: true,
  settingsControl: true,
  videoFoam: true,
};

interface GetEventsAndRecordingsDataArgs {
  showCreditCopy?: boolean;
}
export const getEventsAndRecordingsData = ({
  showCreditCopy = false,
}: GetEventsAndRecordingsDataArgs) =>
  getEvents({ showCreditCopy })?.map(webinar => {
    return {
      ...webinar,
      shareLink: `${config.get('app.portal.url')}${PATH_EVENT_AND_RECORDING}?videoId=${webinar.videoId}`,
      webinarDate: `${webinar.webinarDate}`,
    };
  });

interface GetEventsArgs {
  showCreditCopy?: boolean;
}
// recorded events
export const getEvents = ({ showCreditCopy = false }: GetEventsArgs) => [
  {
    wistiaOptions: {
      ...defaultWistiaOptions,
      thumbnailAltText:
        'Nutritional Management of Canine Chronic Gastrointestinal Disease',
    },
    videoTitle:
      'Nutritional Management of Canine Chronic Gastrointestinal Disease',
    videoId: '5jn1nm5qb1',
    webinarDate: 'May 7th, 2024',
    type: 'CE Course',
    webinarDescription: (
      <>
        <Text as="p" variant="article-16" color="charcoal-3">
          Gastrointestinal disorders are very common in veterinary practice and
          nutrition is a very important aspect of case management. Over 55-60%
          of patients with chronic gastrointestinal disorders will be diet
          responsive. In order to design the best nutrition plan, it is
          important to do a thorough patient assessment by collecting a thorough
          diet history, performing a thorough physical examination and
          completing appropriate diagnostic tests. In this lecture, cases will
          be presented to highlight nutritional assessment, optimal dietary
          management and recommended follow up.
        </Text>
        {!showCreditCopy && (
          <Text as="p" variant="article-16" color="charcoal-3">
            Note: Although CE/RACE credits were available for the live session,
            you will not receive continuing education credit hours for watching
            the recording.
          </Text>
        )}
      </>
    ),
    speakers: [
      {
        nameAndTitle: 'Laura Gaylord, DVM, DACVIM (Nutrition)',
        position: 'Whole Pet Provisions, PLLC',
        description:
          'Laura B. Gaylord is a Board Certified Veterinary Nutritionist® and Diplomat of the American College of Veterinary Internal Medicine (subspecialty Nutrition). She is an independent consultant and the owner/founder of Whole Pet Provisions, PLLC, a nutrition consulting company established in 2016.  She has been a general practitioner in North Carolina for over 24 years working in integrative and physical rehabilitation practices. Through her company, she offers homemade diet recipe formulation and commercial diet consultations for pet parents and their veterinary team, as well as consulting services for pet food and pet supplement companies. Combining her years of clinical experience with passion for nutrition, Dr. Gaylord seeks to promote optimal wellness and integrative veterinary care for companion animals and the pet vested industry.',
        imageSources: lauraProfileSources,
        imageAlt: 'Laura profile picture',
      },
    ],
  },
  {
    wistiaOptions: {
      ...defaultWistiaOptions,
      thumbnailAltText:
        'Nourishing Noses and Tails: Using Physical Exams to Decode Nutrition',
    },
    videoTitle:
      'Nourishing Noses and Tails: Using Physical Exams to Decode Nutrition',
    videoId: 'fvm2zggffn',
    webinarDate: 'March 27th, 2024',
    type: 'CE Course',
    webinarDescription: (
      <>
        <Text as="p" variant="article-16" color="charcoal-3">
          There is more to a nutrition assessment if we just dig a bit deeper
          into patient history and include more observations on physical
          examinations. Case examples will highlight the how and why of more
          in-depth nutritional assessments for our veterinary patients.
        </Text>
        {!showCreditCopy && (
          <Text as="p" variant="article-16" color="charcoal-3">
            Note: Although CE/RACE credits were available for the LIVE session,
            you will not receive continuing education credit hours for watching
            the recording.
          </Text>
        )}
      </>
    ),
    speakers: [
      {
        nameAndTitle: 'Donna M. Raditic DVM, CVA, DACVIM (Nutrition)',
        position: 'Nutrition and Integrative Medicine Consultants',
        description:
          'Dr. Raditic is a Board Certified Veterinary Nutritionist®. She was a professor for both the Nutrition and the Integrative Medicine Services at the University of Tennessee College of Veterinary Medicine. Currently she is owner/founder of Nutrition and Integrative Medicine  Consultants which offers independent consulting and education. Her professional career includes general practice/practice owner, academician, and independent veterinary nutritionist/consultant. Dr. Raditic’s personal and professional journey provides for unique perspectives on the role of nutrition, supplements, and integrative care for our veterinary patients.',
        imageSources: donnaProfileSources,
        imageAlt: 'Donna profile picture',
      },
    ],
  },
  {
    wistiaOptions: {
      ...defaultWistiaOptions,
      thumbnailAltText: "You Can't Judge Pet Food by the Cover",
    },
    videoTitle: "You Can't Judge Pet Food by the Cover",
    videoId: 'njrz3259kr',
    webinarDate: 'September 4th, 2024',
    type: 'CE Course',
    webinarDescription: (
      <>
        <Text as="p" variant="article-16" color="charcoal-3">
          Watch our webinar, &quot;You Can&apos;t Judge Pet Food by the
          Cover&quot; from September 4th, 2024 presented by our on-staff
          veterinarians. Gain valuable insights into how to make the best
          nutritional choices for your patients as we dive into the world of pet
          nutrition and formulation, manufacturing techniques, quality control,
          WSAVA guidelines, and the logistics of distribution and storage.
        </Text>
        {!showCreditCopy && (
          <Text as="p" variant="article-16" color="charcoal-3">
            Note: Although CE/RACE credits were available for the LIVE session,
            you will not receive continuing education credit hours for watching
            the recording.
          </Text>
        )}
      </>
    ),
    speakers: [
      {
        nameAndTitle: 'Brandon Stapleton, DVM',
        description:
          'Dr. Brandon Stapleton graduated from Auburn CVM and practiced in an AAHA-accredited hospital in Indiana for about ten years, where he served as Medical Director and Managing Doctor. From there, he moved into the animal health and nutrition industry with Hill’s, spending most of his tenure there building various teams including those focused on education and digital services to vets. He then joined The Farmer’s Dog to lead their veterinary and professional health organizations.',
        imageSources: brandonProfileSources,
        imageAlt: 'Brandon Stapleton, DVM',
        position: 'Head Veterinarian',
      },
      {
        nameAndTitle: 'Rae Sires, DVM, DACVIM (Nutrition)',
        position: 'Manager, Veterinary and Scientific Communications',
        description:
          'Dr. Rae Sires graduated from Iowa State University College of Veterinary Medicine and immediately moved to Dallas, Texas to complete a 1-year internship focused on small animal emergency and critical care. She completed her residency training in small animal clinical nutrition at UC Davis and became a diplomate of the American College of Veterinary Internal Medicine (Nutrition) in 2021. Dr. Sires joined a small animal general practice after her residency and has worked as a relief veterinarian in 15 different hospitals across Kansas, Missouri, and Texas. She is an active educator with the Veterinary Information Network (VIN) and also serves on the ACVIM General Exam Item Review Committee.',
        imageAlt: 'Rae Sires, DVM, DACVIM (Nutrition)',
        imageSources: raeProfileSources,
      },
    ],
  },
];

export interface VetEvent {
  name: string;
  type: string;
  month: string;
  day: string;
  hourLocation: string;
  speaker?: JSX.Element | string;
  link?: string;
  description?: string;
  linkText?: string;
  linkDisable?: boolean;
  linkOnClick?: () => void;
  icon?: FunctionComponent<
    SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >;
}

interface GetUpcomingEventsArgs {
  includeLunchAndLearn?: boolean;
}
// events from closest to current date to farther in future
export const getUpcomingEvents = ({
  includeLunchAndLearn,
}: GetUpcomingEventsArgs): VetEvent[] => [
  {
    hourLocation: 'Virtual Event',
    day: '23',
    type: 'The Farmer’s Dog Veterinary Advisory Board Meeting',
    month: 'SEP',
    name: 'Fall 2024 Meeting',
    speaker: 'Veterinary Advisory Board, by invitation only',
  },
  {
    hourLocation: 'Orlando, FL',
    day: '25-29',
    type: 'Conference',
    month: 'JAN',
    name: 'VMX (Veterinary Meeting and Expo) 2025',
  },
  {
    hourLocation: 'Las Vegas, NV',
    day: '2-5',
    type: 'Conference',
    month: 'MAR',
    name: 'WVC Annual Conference 2025',
  },
  ...(includeLunchAndLearn
    ? [
        {
          icon: LunchAndLearn,
          hourLocation: 'Virtual',
          day: '',
          type: 'Lunch & Learn',
          month: '',
          name: 'Introduction to The Farmer’s Dog',
          speaker: 'The Farmer’s Dog Veterinary Professionals Team',
          link: externalLinks.PATH_LUNCH_AND_LEARN_REQUEST,
          linkText: 'Request a Lunch and Learn',
          linkOnClick: trackEventsAndRecordingsPageClickedLunchAndLearnRequest,
        },
        {
          icon: University,
          hourLocation: 'In-Person, multiple locations',
          day: '',
          type: 'University Visits',
          month: '',
          name: 'Varies',
          speaker: 'The Farmer’s Dog Veterinary Professionals Team',
          link: externalLinks.PATH_LUNCH_AND_LEARN_REQUEST,
          linkText: 'Request a University Visit',
          linkOnClick:
            trackEventsAndRecordingsPageClickedUniversityVisitRequest,
        },
      ]
    : []),
];

// events from newest to oldest.
export const pastEvents: VetEvent[] = [
  {
    hourLocation: 'Austin, TX',
    day: '22-25',
    type: 'Conference',
    month: 'JUN',
    name: 'AVMA Convention 2024',
    description:
      'At the AVMA Convention 2024, we were proud to support the event as a Silver Sponsorship partner and honored to win “Best Use of Storytelling.” Thank you for your votes, for believing in our story, and for your meaningful contribution to dogs’ stories. Join us next year in Washington D.C.!',
  },
  {
    hourLocation: 'Virtual Event',
    day: '20',
    type: 'The Farmer’s Dog Veterinary Advisory Board Meeting',
    month: 'MAY',
    name: 'Spring 2024 Meeting',
    speaker: 'Veterinary Advisory Board, by invitation only',
  },
  {
    hourLocation: 'North Grafton, MA',
    day: '29',
    type: 'University Visit',
    month: 'APR',
    name: 'Cummings School of Veterinary Medicine at Tufts University',
    speaker: 'Rae Sires, DVM, DACVIM (Nutrition) and Maggie Canning, DVM',
  },
  {
    hourLocation: 'Ithaca, NY',
    day: '25',
    type: 'University Visit',
    month: 'APR',
    name: 'Cornell University College of Veterinary Medicine',
    speaker: 'Brandon Stapleton, DVM, BS',
  },
];
