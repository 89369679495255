import { contactUsFormFieldsData } from './contactUsFormFieldsData';
import { FormFields } from '../FormFields';
import { Button } from '@farmersdog/corgi-x';
import { contactUsFormValidationSchema } from './contactUsFormValidationSchema';
import { initialFormValues } from './initialFormValues';
import { useAddContact } from '../../graphql/hooks/useAddContact';
import { useFormError } from '../FormError';
import { getErrorMessage } from '../../services/auth/utils/errors';
import { useFormSuccess } from '../FormSuccess';
import styles from './ContactUsForm.module.css';
import { useFormikWithFocusOnError } from '../../hooks';

export const ContactUsForm = () => {
  const { FormSuccess, setFormSuccessMessage, clearFormSuccessMessage } =
    useFormSuccess();

  const { FormError, setFormErrorMessage, clearFormErrorMessage } =
    useFormError();

  const [addContact] = useAddContact();

  const formik = useFormikWithFocusOnError({
    initialValues: initialFormValues,
    validationSchema: contactUsFormValidationSchema,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      clearFormSuccessMessage();
      clearFormErrorMessage();
      try {
        await addContact({
          variables: {
            input: {
              ...values,
              contactReason: values.contactReason ?? '',
            },
          },
        });
        setFormSuccessMessage('Message sent!');
      } catch (error) {
        const message = getErrorMessage(error);
        setFormErrorMessage(message);
        return;
      }
      resetForm();
      setSubmitting(false);
    },
  });

  return (
    <div className={styles.formContainer}>
      <form onSubmit={formik.handleSubmit}>
        <div className={styles.inputSection}>
          {/* @ts-expect-error https://github.com/jaredpalmer/formik/issues/2023 */}
          <FormFields fieldData={contactUsFormFieldsData} formik={formik} />
        </div>
        <div>
          <div
            className={styles.messagesContainer}
            aria-live="polite"
            id="form-messages"
            role="status"
          >
            <FormSuccess />
            <FormError />
          </div>
          <div className={styles.buttonContainer}>
            <Button
              disabled={formik.isSubmitting}
              variant="primary"
              className={styles.submitButton}
              type="submit"
              aria-controls="form-messages"
            >
              Submit
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};
