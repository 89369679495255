import styles from './SignUpFormWithPractice.module.css';
import { FormikProps } from 'formik';
import {
  PersonalInformationFormFields,
  PersonalInformationFormValues,
} from './constants';
import { Link } from '@farmersdog/corgi-x';
import { paths } from '@farmersdog/constants';
import { FormFieldArray, FormFields } from '../../FormFields';
import { PracticeInformation } from '../../PracticeInformation';
import { CheckboxWithText } from '../../CheckboxWithText';
import { getAbsoluteLink } from '../../../utils';
import { ValidatePracticeQuery } from '../../../graphql/types';

export interface SignUpFormWithPracticeProps<T extends FormFieldArray> {
  validatedPractice: ValidatePracticeQuery['validatePractice'];
  onGoBack: () => void;
  form: FormikProps<PersonalInformationFormValues>;
  fieldData: T;
}

export function SignUpFormWithPractice<T extends FormFieldArray>({
  validatedPractice,
  onGoBack,
  form,
  fieldData,
}: SignUpFormWithPracticeProps<T>) {
  const name = validatedPractice?.addressComponents?.name ?? '';
  const address = validatedPractice?.formattedAddress ?? '';

  return (
    <div>
      <PracticeInformation address={address} name={name} onUpdate={onGoBack} />
      <form
        id="master-search-form"
        className={styles.formContainer}
        onSubmit={e => {
          e.preventDefault();
          form.handleSubmit(e);
        }}
      >
        <div className={styles.containerFields}>
          {/* @ts-expect-error https://github.com/jaredpalmer/formik/issues/2023 */}
          <FormFields formik={form} fieldData={fieldData} />
        </div>
        <CheckboxWithText
          maxWidth
          checked={
            form.values[PersonalInformationFormFields.acceptedPrivacyPolicy]
          }
          onChange={e => {
            form.handleChange(e);
          }}
          onBlur={form.handleBlur}
          error={
            form.errors[PersonalInformationFormFields.acceptedPrivacyPolicy]
          }
          isTouched={
            form.touched[PersonalInformationFormFields.acceptedPrivacyPolicy]
          }
          fieldName={PersonalInformationFormFields.acceptedPrivacyPolicy}
          labelContent={
            <>
              By clicking here, I have read and agree to The Farmer’s Dog’s{' '}
              <Link
                href={getAbsoluteLink(paths.PATH_TERMS)}
                target={'_blank'}
                className={styles.termsOfUse}
              >
                Terms of Use
              </Link>
              .
            </>
          }
        />
        <div className={styles.checkbox}>
          <CheckboxWithText
            maxWidth
            checked={
              form.values[PersonalInformationFormFields.attestedAtSignup]
            }
            onChange={e => {
              form.handleChange(e);
            }}
            onBlur={form.handleBlur}
            error={form.errors[PersonalInformationFormFields.attestedAtSignup]}
            isTouched={
              form.touched[PersonalInformationFormFields.attestedAtSignup]
            }
            fieldName={PersonalInformationFormFields.attestedAtSignup}
            labelContent={
              <>
                By clicking here, I attest that the information I have provided
                to gain access to this site, intended strictly for veterinary
                professionals, is true and accurate to the best of my knowledge.
                I understand that if this information is found to be untrue, the
                company reserves the right to change or deny access to the site
                and any benefits contained therein.
              </>
            }
          />
        </div>
      </form>
    </div>
  );
}
