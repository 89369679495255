import { FC } from 'react';

import { useAuthContext } from '../../context';

import { Button, Link } from '@farmersdog/corgi';
import { Text } from '@farmersdog/corgi-x';

import { FormFields } from '../FormFields';
import { useFormError } from '../FormError';
import { useFormSuccess } from '../FormSuccess';

import { trackRequestedResetPassword } from '../../analytics/events';

import {
  getErrorMessage,
  errorCodes,
  defaultErrorMessage,
} from '../../services/auth/utils/errors';

import {
  initialFormValues,
  ForgotPasswordFormValidationSchema,
  ForgotPasswordFormFieldsData,
} from './constants';
import * as pagePaths from '../../constants';

import styles from './ForgotPasswordForm.module.css';
import { useFormikWithFocusOnError } from '../../hooks';
import { isErrorWithName } from '../../utils';
import { Logger } from '@farmersdog/logger';

export const ForgotPasswordForm: FC = () => {
  const { forgotPassword } = useAuthContext();

  const { FormSuccess, setFormSuccessMessage, clearFormSuccessMessage } =
    useFormSuccess();

  const { FormError, setFormErrorMessage, clearFormErrorMessage } =
    useFormError();

  const formFieldsData = ForgotPasswordFormFieldsData;

  const formik = useFormikWithFocusOnError({
    initialValues: initialFormValues,
    validationSchema: ForgotPasswordFormValidationSchema,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      clearFormSuccessMessage();
      clearFormErrorMessage();
      try {
        if (!forgotPassword) {
          throw new Error('No forgotPassword function provided');
        }
        await forgotPassword({ email: values.email });
        trackRequestedResetPassword({ email: values.email, result: true });
        setFormSuccessMessage(
          `We have sent an email to ${values.email} with further instructions.`
        );
        resetForm();
      } catch (error) {
        trackRequestedResetPassword({ email: values.email, result: false });
        const message = getErrorMessage(error as Error);

        if (isErrorWithName(error)) {
          if (error.name === errorCodes.USER_NOT_FOUND) {
            setFormSuccessMessage(
              `We have sent an email to ${values.email} with further instructions.`
            );
          } else {
            setFormErrorMessage(message);
          }
        }
        resetForm();
        // default error message indicates an unexpected error
        if (message === defaultErrorMessage) {
          const logger = new Logger('auth:forgotPassword');
          logger.error('Unexpected error occurred', { error });
        }
        return;
      }
      setSubmitting(false);
    },
  });

  return (
    <div className={styles.formContainer}>
      <form className={styles.form} onSubmit={formik.handleSubmit}>
        <div
          className={styles.messagesContainer}
          aria-live="polite"
          id="form-messages"
          role="status"
        >
          <FormError />
          <FormSuccess />
        </div>
        <div className={styles.inputSection}>
          {/* @ts-expect-error https://github.com/jaredpalmer/formik/issues/2023 */}
          <FormFields fieldData={formFieldsData} formik={formik} />
        </div>
        <div className={styles.buttonContainer}>
          <Button
            disabled={formik.isSubmitting}
            variant="primary"
            className={styles.submitButton}
            type="submit"
            aria-controls="form-messages"
          >
            Submit
          </Button>
          <Text className={styles.loginButton} as="p" variant="heading-16">
            <Link to={pagePaths.PATH_LOGIN} variant="primary">
              Back to Login
            </Link>
          </Text>
        </div>
      </form>
    </div>
  );
};
