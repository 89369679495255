import {
  Button,
  Divider,
  Grid,
  GridItem,
  Link,
  Text,
} from '@farmersdog/corgi-x';
import { emails } from '@farmersdog/constants';
import { AccountPage } from '../../components/AccountPage';
import { FAQsMenu } from './FAQsMenu';
import FAQIcon from './assets/faqIcon.svg?react';
import EnvelopeIcon from './assets/envelope.svg?react';

import { QuestionDropdown } from './QuestionDropdown';
import { useVetDetails } from '../../graphql/hooks/useVetDetails';
import * as pagePaths from '../../constants/pagePaths';
import { trackClickedFAQActivateDiscountButton } from '../../analytics/events';

import styles from './FAQs.module.css';
import { generalQuestions, researchAndSafetyStandardQuestions } from './data';
import { menuItems, scrollAnchorNames } from './menuItems';

export const FAQs = () => {
  const { data } = useVetDetails();

  const discountRequests = data?.vetDetails?.vet?.discountRequests || [];

  const noDiscountsRequested = discountRequests.length === 0;

  const generalId = scrollAnchorNames.general;
  const researchId = scrollAnchorNames.research;

  const menuItemRefs = {
    [scrollAnchorNames.general]: generalId,
    [scrollAnchorNames.research]: researchId,
  };

  return (
    <AccountPage fullWidth>
      <Grid justifyContent="center" columnGap="md" className={styles.container}>
        <GridItem lg={4} className={styles.nav}>
          <Text
            bold
            as="h2"
            topSpacing="none"
            bottomSpacing="lg"
            color="blueberry-3"
            variant="heading-40"
          >
            Frequently Asked Questions for Vet Professionals
          </Text>
          <FAQsMenu menuItems={menuItems} menuItemRefs={menuItemRefs} />
          <FAQIcon className={styles.icon} />
        </GridItem>
        <GridItem className={styles.questionsContainer} lg={8}>
          <div id={menuItemRefs[scrollAnchorNames.general]}>
            <Text
              bold
              as="h3"
              topSpacing={{
                xs: 'xl',
                lg: 'none',
              }}
              bottomSpacing="md"
              color="blueberry-3"
              variant="heading-28"
              className={styles.title}
            >
              General
            </Text>
            {generalQuestions.map(({ question, answer }) => (
              <QuestionDropdown
                key={question}
                question={question}
                answer={answer}
              />
            ))}
          </div>
          <div id={menuItemRefs[scrollAnchorNames.research]}>
            <Text
              bold
              as="h3"
              topSpacing="xl"
              color="blueberry-3"
              variant="heading-28"
            >
              Research and Safety Standards
            </Text>
            {researchAndSafetyStandardQuestions.map(({ question, answer }) => (
              <QuestionDropdown
                key={question}
                question={question}
                answer={answer}
              />
            ))}
          </div>
          <Divider color="Oyster1" spacing={48} />
          <Text variant="heading-22" as="h4" bold color="blueberry-3">
            Can’t find what you are looking for? Reach out:
          </Text>
          <Grid bottomSpacing="lg" alignItems="center" columnGap="xs">
            <EnvelopeIcon className={styles.contactIcon} />
            <Link to={`mailto:${emails.EMAIL_VET}`}>
              <Text variant="heading-16" color="kale-3">
                {emails.EMAIL_VET}
              </Text>
            </Link>
          </Grid>

          {noDiscountsRequested && (
            <Button
              onClick={() => trackClickedFAQActivateDiscountButton()}
              type="link"
              to={pagePaths.PATH_TRIAL_DISCOUNTS}
            >
              Activate Vet Discount
            </Button>
          )}
        </GridItem>
      </Grid>
    </AccountPage>
  );
};
