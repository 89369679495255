import { Text, Grid, GridItem } from '@farmersdog/corgi-x';
import { Card } from '../../components';

import { AccountPage } from '../../components/AccountPage';
import { DownloadList } from './DownloadList';
import { AdditionalList } from './AdditionalList';

import styles from './Resources.module.css';
import { ResponsiveProps } from '../../constants';

export const ResourcesResponsive = ({ isMobile }: ResponsiveProps) => {
  return (
    <AccountPage>
      <Grid>
        <GridItem>
          <Text
            as="h2"
            variant={isMobile ? 'heading-28' : 'heading-40'}
            bold
            color="blueberry-3"
            topSpacing="none"
            bottomSpacing="none"
          >
            Nutrition Guide, Research, and Clinical Resources
          </Text>
        </GridItem>
        <Grid className={styles.container} rowGap="md">
          <GridItem>
            <Card className={styles.card}>
              <div className={styles.titleContainer}>
                <Text
                  as="h2"
                  variant="heading-28"
                  bold
                  color="blueberry-3"
                  className={styles.title}
                >
                  Resources from The Farmer&apos;s Dog
                </Text>
              </div>
              <DownloadList />
            </Card>
          </GridItem>
          <GridItem id="studies"></GridItem>
          <GridItem>
            <Card className={styles.card}>
              <div className={styles.titleContainer}>
                <Text
                  as="h3"
                  variant="heading-28"
                  bold
                  color="blueberry-3"
                  className={styles.title}
                >
                  Additional Studies and Research
                </Text>
                <Text as="p" variant="heading-16" className={styles.subtitle}>
                  Like you, we’re driven by science. We know there’s endless
                  information available on pet food and health — here are some
                  tools and published nutrition research as a great starting
                  place.
                </Text>
              </div>
              <AdditionalList />
            </Card>
          </GridItem>
        </Grid>
      </Grid>
    </AccountPage>
  );
};

export const Resources = () => {
  return (
    <>
      <div className={styles.mobile}>
        <ResourcesResponsive isMobile={true} />
      </div>
      <div className={styles.desktop}>
        <ResourcesResponsive isMobile={false} />
      </div>
    </>
  );
};
