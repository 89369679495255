import { ReactNode } from 'react';

import { ContentWidth, Grid, GridItem, PageWidth } from '@farmersdog/corgi-x';
import { AccountHeader } from '../AccountHeader';
import { PortalBanner } from '../PortalBanner';
import { FullFooter } from '../FullFooter';
import { useVetDetails } from '../../graphql/hooks/useVetDetails';

import styles from './AccountPage.module.css';
import { usePublicPageFeatures } from '../../abTesting/PublicPageFeatures';
import { useVetPortalLunchAndLearn } from '../../abTesting';
import classNames from 'classnames';
import { useScreenSizeContext } from '../../context/ScreenSizeContext';

interface PageProps {
  children: ReactNode;
  fullWidth?: boolean;
}

export function AccountPage({ children, fullWidth = false }: PageProps) {
  const { data } = useVetDetails();

  const { isMobile } = useScreenSizeContext();

  const discountRequests = data?.vetDetails?.vet?.discountRequests || [];

  const { showPortalBanner, bannerConfig } = usePublicPageFeatures();
  const { showVetPortalLunchAndLearn } = useVetPortalLunchAndLearn();

  const backgroundClassnames = classNames({
    [styles.kaleBackground]: !fullWidth,
    [styles.whiteBackground]: fullWidth,
  });

  return (
    <>
      {showPortalBanner && (
        <PortalBanner
          link={bannerConfig?.link ?? ''}
          buttonVerbiage={bannerConfig?.buttonVerbiage ?? ''}
          textVerbiage={bannerConfig?.textVerbiage ?? ''}
        />
      )}
      <AccountHeader discountRequests={discountRequests} isMobile={isMobile} />
      <div className={backgroundClassnames}>
        <PageWidth>
          {!showVetPortalLunchAndLearn && (
            <ContentWidth
              className={styles.pageContent}
              fr={fullWidth ? 3 : 2}
              alignSelf="center"
            >
              {children}
            </ContentWidth>
          )}
          {showVetPortalLunchAndLearn && (
            <Grid
              justifyContent="center"
              bottomSpacing="xxl"
              topSpacing="xxl"
              className={classNames({ [styles.content]: !fullWidth })}
            >
              <GridItem
                xl={8}
                className={classNames({ [styles.fullWidth]: fullWidth })}
              >
                {children}
              </GridItem>
            </Grid>
          )}
        </PageWidth>
      </div>
      <FullFooter isAccountPage={true} />
    </>
  );
}
