import { getCookieDomain } from '@farmersdog/sessions/src/storage/getCookieDomain';
import cookie from 'js-cookie';

const TERMS_CHANGES_NOTICE_DISMISSED_KEY = 'tfd-terms-changes-notice-dismissed';

export function getTermsChangesNoticeDismissed(): string | undefined {
  return cookie.get(TERMS_CHANGES_NOTICE_DISMISSED_KEY);
}

export function setTermsChangesNoticeDismissed(): void {
  cookie.set(TERMS_CHANGES_NOTICE_DISMISSED_KEY, 'true', {
    domain: getCookieDomain(),
    expires: 30,
  });
}
