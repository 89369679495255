import firstVetWebp from '../assets/vet1.webp';
import firstVetJp2 from '../assets/vet1.jp2';
import firstVetPng from '../assets/vet1.png';

import secondVetWebp from '../assets/vet2.webp';
import secondVetJp2 from '../assets/vet2.jp2';
import secondVetPng from '../assets/vet2.png';

import thirdVetWebp from '../assets/vet3.webp';
import thirdVetJp2 from '../assets/vet3.jp2';
import thirdVetPng from '../assets/vet3.png';

const firstVetSources = [firstVetWebp, firstVetJp2, firstVetPng];
const secondVetSources = [secondVetWebp, secondVetJp2, secondVetPng];
const thirdVetSources = [thirdVetWebp, thirdVetJp2, thirdVetPng];

export type TestimonialData = {
  images: string[];
  vetName: string;
  hospitalInfo?: string;
  testimonialText: string;
};

export const testimonialData: TestimonialData[] = [
  {
    images: firstVetSources,
    vetName: 'Dr. Deepti Johar',
    testimonialText:
      '“Choosing what food to feed your dog is a decision that should not be taken lightly, and choosing one that’s high-quality and fresh is one of the healthiest daily decisions you can make for them.”',
  },
  {
    images: secondVetSources,
    vetName: 'Dr. Alex Schechter',
    testimonialText:
      '“No animal’s digestive system is designed to eat highly-processed food every meal of their lives. The Farmer’s Dog makes it easy to feed your pet something better.”',
  },
  {
    images: thirdVetSources,
    vetName: 'Dr. Jonathan Block',
    testimonialText:
      '“Obesity in dogs is an immense and worsening problem. Feeding a truly healthy, pre-portioned food can add years to your dog’s life and save you thousands of dollars.”',
  },
];
