import { useId } from 'react';
import { Button, Section, Text } from '@farmersdog/corgi-x';
import * as pagePaths from '../../../constants/pagePaths';

interface PersonalDetailsProps {
  name: string;
  email: string;
}
export const PersonalDetails = ({ email, name }: PersonalDetailsProps) => {
  const headingId = useId();
  return (
    <Section aria-labelledby={headingId} variant="card">
      <Text
        as="h2"
        id={headingId}
        variant="heading-28"
        bold
        color="blueberry-3"
      >
        My Personal Details
      </Text>
      <Text variant="heading-16" color="charcoal-3">
        <strong>Name</strong>
        <br />
        {name}
      </Text>
      <Text as="p" variant="heading-16" color="charcoal-3">
        <strong>Contact Email</strong>
        <br />
        {email}
      </Text>
      <Button
        variant="plain-text"
        underline
        weight="normal"
        type="link"
        data-testid="update-personal"
        to={{
          pathname: pagePaths.PATH_PROFILE,
          search: 'update=personal',
        }}
      >
        Update
      </Button>
    </Section>
  );
};
