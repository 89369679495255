import { useRef, FC } from 'react';
import { Hero } from './Hero';
import { HealthyDogsSection } from './HealthyDogsSection/HealthyDogsSection';
import { ScienceAndSafetySection } from './ScienceAndSafetySection';
import { SolutionsSection } from './SolutionsSection';
import { WhatFreshFoodIsSection } from './WhatFreshFoodIsSection';
import { WhatFreshFoodIsNotSection } from './WhatFreshFoodIsNotSection';
import { BackedByScienceSection } from './BackedByScienceSection';
import { MeetOurTeamSection } from './MeetOurTeamSection';
import { FAQSection } from './FAQSection';
import { RegisterBanner } from './RegisterBanner';

import { PublicPage } from '../../components';

import styles from './Home.module.css';

export const Home: FC = () => {
  const ctaReveal = useRef<HTMLDivElement>(null);
  return (
    <>
      <PublicPage showCTA={ctaReveal}>
        <Hero />
        <RegisterBanner />
        <div ref={ctaReveal} className={styles.container}>
          <HealthyDogsSection />
          <ScienceAndSafetySection />
          <SolutionsSection />
          <MeetOurTeamSection />
          <WhatFreshFoodIsSection />
          <WhatFreshFoodIsNotSection />
          <BackedByScienceSection />
          <FAQSection />
        </div>
      </PublicPage>
    </>
  );
};
