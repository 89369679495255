import Cookie from 'js-cookie';
import type { SessionData } from '../utils';

import { isValidSessionData } from '../utils';
import { getCookieDomain } from './getCookieDomain';

export const STORAGE_KEY = 'tfd-segment-session-data';

export type CookieStorageSessionDataReturn = SessionData | null;

/**
 * Retrieve the session id from local storage
 */
function get(): CookieStorageSessionDataReturn {
  const sessionDataString = Cookie.get(STORAGE_KEY);
  try {
    if (!sessionDataString) {
      return null;
    }

    const parsedSessionData = JSON.parse(sessionDataString) as unknown;
    return isValidSessionData(parsedSessionData) ? parsedSessionData : null;
  } catch {
    return null;
  }
}

/**
 * Write the session id to local storage
 */
function set(sessionData: SessionData) {
  const sessionDataString = JSON.stringify(sessionData);
  Cookie.set(STORAGE_KEY, sessionDataString, {
    path: '/',
    expires: new Date(sessionData.expiration),
    domain: getCookieDomain(),
  });
}

export const cookieStorageSessionData = {
  get,
  set,
};
