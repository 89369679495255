import { AccountPage } from '../../components/AccountPage';
import styles from './EventsAndRecordings.module.css';
import { Grid, Text } from '@farmersdog/corgi-x';
import { Events, EventType } from './Events';
import { OnDemandVideos } from './OnDemandVideos';
import {
  getUpcomingEvents,
  pastEvents,
} from '../../data/eventsAndRecordingsData';
import Doggie from './assets/Doggie.svg?react';
import { useVetPortalLunchAndLearn } from '../../abTesting';
import { useScreenSizeContext } from '../../context/ScreenSizeContext';

export const EventsAndRecordings = () => {
  const { showVetPortalLunchAndLearn } = useVetPortalLunchAndLearn();
  const upcomingEvents = getUpcomingEvents({
    includeLunchAndLearn: showVetPortalLunchAndLearn,
  });

  const { isMobile } = useScreenSizeContext();

  return (
    <AccountPage>
      <Text
        as="h2"
        variant={isMobile ? 'heading-28' : 'heading-40'}
        bold
        color="blueberry-3"
        topSpacing="none"
        bottomSpacing={isMobile ? 'md' : 'lg'}
        className={styles.title}
      >
        Events & Recordings
      </Text>
      <Grid className={styles.svgContainer}>
        <Doggie className={styles.doggieSvg} aria-label="Doggie Icon" />
      </Grid>
      <Grid rowGap="md" flexDirection="column">
        <Events
          isMobile={isMobile}
          eventType={EventType.UpcomingEvents}
          events={upcomingEvents}
        />
        <OnDemandVideos isMobile={isMobile} />
        <Events
          isMobile={isMobile}
          eventType={EventType.PastEvents}
          events={pastEvents}
        />
      </Grid>
    </AccountPage>
  );
};
